import { useState, useEffect, useRef, useContext } from 'react';
import { createContext } from 'react';
import { Button, Space, Input, Table, Form, Popconfirm, Tooltip } from 'antd';
import { SearchOutlined, CloseOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import { LocaleContext } from '../../context/LocaleContext';
import { useAxios } from '../../util/AxiosUtil';
import { useKeycloak } from '@react-keycloak/web';

import dayjs from 'dayjs';

const EditableContext = createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  const { i18n, t } = useTranslation();

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave(record.costPlanId, record.year, values);
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: t("page.field_is_mandatory"),
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <Tooltip>
        <div
          className="editable-cell-value-wrap"
          style={{ paddingRight: 24 }}
          onClick={toggleEdit}
        >
          {children}
        </div>
      </Tooltip>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

const getMonthIndex = (month) => {

  if ('jan' === month) return 1;
  if ('feb' === month) return 2;
  if ('mar' === month) return 3;
  if ('apr' === month) return 4;
  if ('may' === month) return 5;
  if ('jun' === month) return 6;
  if ('jul' === month) return 7;
  if ('aug' === month) return 8;
  if ('sep' === month) return 9;
  if ('oct' === month) return 10;
  if ('nov' === month) return 11;
  if ('dec' === month) return 12;
};

const CostPlanTable = ({ boilerRoomData, selectedYear, setRefresh, refresh }) => {

  const { i18n, t } = useTranslation();
  const { locale, setLocale } = useContext(LocaleContext);

  const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);
  const { keycloak } = useKeycloak();

  const [isLoading, setIsLoading] = useState(false);
  const [costPlanListData, setCostPlanListData] = useState([]);
  const [limit, setLimit] = useState(5);
  const [pagination, setPagination] = useState({});
  const [formData, setFormData] = useState({});
  const [searchValue, setSearchValue] = useState();

  useEffect(() => {
      customFetch({offset: 1, limit: limit, orderBy: 'createdOn', order: 'descend'});
  }, [selectedYear, locale]);
  
  useEffect(() => {
    if (refresh === true)
      customFetch({offset: 1, limit: limit, orderBy: 'createdOn', order: 'descend'});
    
    setRefresh(false);
  }, [refresh]);

  const customFetch = async (searchCriteria = {}) => {
    setIsLoading(true);
    await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/costPlan?boilerRoomId='+boilerRoomData.id+'&search=year:'+selectedYear, {
      params: searchCriteria
    }).then(response => {

      const result = response.data.content.reduce((acc, {costPlanType, id, year, costPlanItems}) => {
        acc[name] ??= {name: t(costPlanType.name), costPlanId: id, year: year};
        
        costPlanItems.forEach(element => {
          if (element.month === 1)
            acc[name].jan = element.value;
          if (element.month === 2)
            acc[name].feb = element.value;
          if (element.month === 3)
            acc[name].mar = element.value;
          if (element.month === 4)
            acc[name].apr = element.value;
          if (element.month === 5)
            acc[name].may = element.value;
          if (element.month === 6)
            acc[name].jun = element.value;
          if (element.month === 7)
            acc[name].jul = element.value;
          if (element.month === 8)
            acc[name].aug = element.value;
          if (element.month === 9)
            acc[name].sep = element.value;
          if (element.month === 10)
            acc[name].oct = element.value;
          if (element.month === 11)
            acc[name].nov = element.value;
          if (element.month === 12)
            acc[name].dec = element.value;
        });

        return acc;
      }, {});

      const data = [];
      for (const [key, value] of Object.entries(result)) {
        data.push(value);
      }
      
      setCostPlanListData(data);
      setPagination({current: searchCriteria.offset, pageSize: searchCriteria.limit, orderBy: 'createdOn', order: 'descend', total: response.data.totalElements});
      setIsLoading(false);
    }).catch('error: ' + console.log);
  };

  const handleTableChange = (
      pagination,
      filters,
      sorter
    ) => {
      if (sorter.columnKey === undefined)
        sorter.columnKey = pagination.orderBy;

      customFetch({
        offset: pagination.current,
        limit: pagination.pageSize,
        orderBy: sorter.columnKey,
        order: sorter.order,
        ...formData
      });
  };

  const onSearch = (searchKey) => {
    var search = {search: searchKey+":"+searchValue};
    customFetch({
      offset: 1,
      limit: limit,
      orderBy: 'createdOn',
      ...search
    });
  };

  function getColumnSearchProps(dataIndex) {
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={t("page.search")}
            onChange={e => setSearchValue(e.target.value ? [e.target.value] : [])}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => onSearch(dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              {t("page.search")}
            </Button>
            <Button
              onClick={() => {
                confirm({ closeDropdown: true });
              }}
              icon={<CloseOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              {t("page.close")}
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    }
  }

  const handleSave = async (costPlanId, year, valObj) => {

    let month, value;
    let entries = Object.entries(valObj);
    entries.map( ([key, val] = entry) => {
      month = getMonthIndex(key);
      value = val;
    });

    var data = {};
    data.costPlanId = costPlanId;
    data.year = year;
    data.month = month;
    data.value = value;
    data.modifiedOn = dayjs().format("YYYY-MM-DD");
    data.modifiedBy = keycloak.idTokenParsed.preferred_username;

    await axiosClient.put(process.env.REACT_APP_ENERPO_DATA_PATH+'/costPlanItem', data)
    .then(response => {
      customFetch({offset: 1, limit: limit, orderBy: 'createdOn', order: 'descend'});
    }).catch('error: ' + console.log);
  }

  const handleDelete = async (costPlanId) => {
    await axiosClient.delete(process.env.REACT_APP_ENERPO_DATA_PATH+'/costPlan/'+costPlanId)
    .then(response => {
      customFetch({offset: 1, limit: limit, orderBy: 'createdOn', order: 'descend'});
    }).catch('error: ' + console.log);
  };

  const defaultColumns = [
    {
      title: t("page.name"),
      dataIndex: 'name',
      key: 'name',
      width: 200,
      sorter: (a, b) => a.name - b.name,
      sortDirections: ['ascend', 'descend', 'ascend'],
      ...getColumnSearchProps('name'),
      fixed: 'left',
    },{
      title: t("page.january"),
      dataIndex: 'jan',
      key: 'jan',
      width: 100,
      editable: true,
      render: (text) => <span>{text}</span>
    },{
      title: t("page.february"),
      dataIndex: 'feb',
      key: 'feb',
      width: 100,
      editable: true,
      render: (text) => <span>{text}</span>
    },{
      title: t("page.march"),
      dataIndex: 'mar',
      key: 'mar',
      width: 100,
      editable: true,
      render: (text) => <span>{text}</span>
    },{
      title: t("page.april"),
      dataIndex: 'apr',
      key: 'apr',
      width: 100,
      editable: true,
      render: (text) => <span>{text}</span>
    },{
      title: t("page.may"),
      dataIndex: 'may',
      key: 'may',
      width: 100,
      editable: true,
      render: (text) => <span>{text}</span>
    },{
      title: t("page.jun"),
      dataIndex: 'jun',
      key: 'jun',
      width: 100,
      editable: true,
      render: (text) => <span>{text}</span>
    },{
      title: t("page.july"),
      dataIndex: 'jul',
      key: 'jul',
      width: 100,
      editable: true,
      render: (text) => <span>{text}</span>
    },{
      title: t("page.august"),
      dataIndex: 'aug',
      key: 'aug',
      width: 100,
      editable: true,
      render: (text) => <span>{text}</span>
    },{
      title: t("page.september"),
      dataIndex: 'sep',
      key: 'sep',
      width: 100,
      editable: true,
      render: (text) => <span>{text}</span>
    },{
      title: t("page.october"),
      dataIndex: 'oct',
      key: 'oct',
      width: 100,
      editable: true,
      render: (text) => <span>{text}</span>
    },{
      title: t("page.november"),
      dataIndex: 'nov',
      key: 'nov',
      width: 100,
      editable: true,
      render: (text) => <span>{text}</span>
    },{
      title: t("page.december"),
      dataIndex: 'dec',
      key: 'dec',
      width: 100,
      editable: true,
      render: (text) => <span>{text}</span>
    },{
      title: t("page.action"),
      key: 'operation',
      fixed: 'right',
      width: 100,
      render: (record) => 
        <Popconfirm
          title={t("page.delete_cost_plan")}
          description={t("page.delete_cost_plan_confirm")}
          onConfirm={() => handleDelete(record.costPlanId)}
          okText={t("page.yes")}
          cancelText={t("page.no")}
        >
          <a>{t("page.delete")}</a>
        </Popconfirm>,
    },
  ];

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  return (
    <Table
      rowKey={costPlanListData => costPlanListData.id}
      columns={columns}
      dataSource={costPlanListData}
      components={components}
      rowClassName={() => 'editable-row'}
      pagination={pagination}
      onChange={handleTableChange}
      loading={isLoading}
      size="small"
      scroll={{
        x: 'calc(700px + 50%)',
        y: 240,
      }}
    />
  );
};

export default CostPlanTable;