import { useState, useCallback } from 'react';
import { Button, Card, Row, Col } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";

import isInRole from '../../security/IsInRole';
import Unauthorized from '../../template/error/Unauthorized';

import CompanyTable from '../company/CompanyTable.js';
import CompanyBoilerRoomCnt from '../chart/CompanyBoilerRoomCnt.js';
import BoilerRoomMap from '../boiler_room/BoilerRoomMap.js';
import BoilerRoomInsert from '../boiler_room/BoilerRoomInsert.js';

const CompanyList = () => {
  
  const { i18n, t } = useTranslation();

  const [refreshForm, setRefreshForm] = useState(false);
  const [visibleBrForm, setVisibleBrForm] = useState(false);
  const [refreshBrForm, setRefreshBrForm] = useState(false);

  const showInsertBrForm = useCallback((value) => {
      if (value === 'refresh') {
          setVisibleBrForm(false);
          setRefreshBrForm(true);
      } else {
          setVisibleBrForm(value);
      }
  }, []);

  return (
    <>
      {isInRole(['enerpo_web']) ?
      <>
        <Row gutter={16} style={{ marginTop: 10, marginBottom: 10 }}>
          <Col span={24}>
              <Card size="small" title={t("page.companies")+":"}>
                  <CompanyTable setRefresh={setRefreshForm} refresh={refreshForm} />
              </Card>
          </Col>
        </Row>


        <Row gutter={[10,10]} style={{ marginTop: 10, marginBottom: 20 }}>
          <Col span="24">
            <Card size="small" title={t("page.companies.boiler_rooms")+":"}>
                <BoilerRoomMap height={200} zoom={7} />
            </Card>
          </Col>
        </Row>

      </>
      : <Unauthorized />}
    </>
  );
};

export default CompanyList;