import { useState, useEffect } from 'react';
import { Button, Modal, Table, Divider } from 'antd';
import { useAxios } from '../../../util/AxiosUtil';
import { useTranslation } from "react-i18next";

import dayjs from 'dayjs';

const BoilerRoomSensorConsumeLogAudit = ({ isModalVisible, setIsModalVisible, measureId }) => {

  const dateTimeFromat = "DD.MM.YYYY HH:mm:ss";
  const { i18n, t } = useTranslation();
  const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);
  const [isLoading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useState({});
  const [auditData, setAuditData] = useState();

  useEffect(() => {
    if (measureId)
      fetchMeasureLogAudit({offset: 1, limit: 5, orderBy: 'modifiedOn'});
  }, [isModalVisible, measureId])

  const fetchMeasureLogAudit = async (searchCriteria = {}) => {
    setIsLoading(true);
    await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/audit/boilerRoomConsume/'+measureId, {
      params: searchCriteria
    }).then(response => {
      setAuditData(response.data.content);
      setPagination({current: searchCriteria.offset, pageSize: searchCriteria.limit, orderBy: 'modifiedOn', order: 'descend', total: response.data.totalElements});
      setIsLoading(false);
    }).catch('error: ' + console.log);
  };

  const handleTableChange = (
    pagination
  ) => {
    fetchMeasureLogAudit({
      offset: pagination.current,
      limit: pagination.pageSize,
      orderBy: 'modifiedOn'
    });
  };

  const closeAudit = () => {
    setIsModalVisible(false);
  }
  
  const columns = [
    {
      title: t("page.changed_item"),
      dataIndex: 'columnName',
      key: 'columnName',
      render: (text, record, index) => 
        <div>
          { record.columnName === 'value' ? t("page.value") : t("page.unit") }
        </div>
    },{
      title: t("page.old_value"),
      dataIndex: 'valueFrom',
      key: 'valueFrom',
      render: (text, record, index) => <div> { parseFloat(record.valueFrom).toFixed(3) } </div>
    },{
      title: t("page.new_value"),
      dataIndex: 'valueTo',
      key: 'valueTo',
      render: (text, record, index) => <div> { parseFloat(record.valueTo).toFixed(3) } </div>
    },{
      title: t("page.modified_by"),
      dataIndex: 'modifiedBy',
      key: 'modifiedBy'
    },{
      title: t("page.modified_on"),
      dataIndex: 'modifiedOn',
      key: 'modifiedOn',
      render: (text, record, index) => <div>{ dayjs(record.modifiedOn).format(dateTimeFromat) }</div>
    }
  ]

  return (
    <Modal
      title={t("page.history")}
      visible={isModalVisible}
      width={800}
      onCancel={closeAudit}
      footer={[
        <Button key="back" onClick={closeAudit}>{t("page.close")}</Button>
      ]}
    >
      <Divider />
      <Table
        rowKey="id"
        columns={columns}
        dataSource={auditData}
        pagination={pagination}
        onChange={handleTableChange}
        loading={isLoading}
        size="small"
      />
    </Modal>
  );
};

export default BoilerRoomSensorConsumeLogAudit;