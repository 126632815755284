import { useState, useEffect } from 'react';
import { Spin, Statistic, Card } from 'antd';
import { useAxios } from '../../../util/AxiosUtil';
import { useTranslation } from "react-i18next";
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';

const HpStatusChart = ({ heatPumpId }) => {

  const { i18n, t } = useTranslation();
  const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);

  const [runningCnt, setRunningCnt] = useState(0);
  const [brokenCnt, setBrokenCnt] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    customFetch();
  }, [heatPumpId]);

  const customFetch = async () => {
    setIsLoading(true);
    await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/heatPump/'+heatPumpId+'/status')
    .then(response => {

      for (var k = 0; k < response.data.length; k++) {
        if (response.data[k].name === 'TČ - bežiace') setRunningCnt(response.data[k].cnt);
        if (response.data[k].name === 'TČ - v poruche') setBrokenCnt(response.data[k].cnt);
      }

      setIsLoading(false);
    }).catch('error: ' + console.log);
  };

  return (
    <Spin spinning={isLoading}>
      <Card bordered={true} size='small' style={{ marginTop: 10, marginBottom: 20 }}>
        <Statistic
          value={runningCnt}
          precision={0}
          valueStyle={{
            color: '#3f8600',
            fontSize: 18
          }}
          prefix={<ArrowUpOutlined />}
          suffix={"- " + t("bežiace")}
        />
      </Card>
      <Card bordered={true} size='small'>
        <Statistic
          value={brokenCnt}
          precision={0}
          valueStyle={{
            color: '#cf1322',
            fontSize: 18
          }}
          prefix={<ArrowDownOutlined />}
          suffix={"- " + t("v poruche")}
        />
      </Card>
    </Spin>
  );
};

export default HpStatusChart;