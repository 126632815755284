import { useState, useEffect } from 'react';
import { Tabs, Typography, Card, Row, Col, Descriptions } from 'antd';
import { useParams, useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAxios } from '../../util/AxiosUtil';

import isInRole from '../../security/IsInRole';
import BranchSensorMeasureLogCard from './measure/BranchSensorMeasureLogCard';
import BranchSensorConsumeLogCard from './consume/BranchSensorConsumeLogCard';

const BranchSensor = () => {
    
    const { i18n, t } = useTranslation();

    let params = useParams();
    const { TabPane } = Tabs;
    const { Title } = Typography;

    const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);
    const navigate = useNavigate();

    const [sensorData, setSensorData] = useState([]);
    const [branchData, setBranchData] = useState([]);
    const [boilerRoomData, setBoilerRoomData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      customFetch();
    }, [params.id]);

    const customFetch = async () => {
      setIsLoading(true);
      await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/branchSensor/'+params.branchId+'/'+params.sensorId)
      .then(response => {
          setSensorData(response.data);
          setBranchData(response.data.branch);
          setBoilerRoomData(response.data.branch.boilerRoom);
          setIsLoading(false);
      }).catch('error: ' + console.log);
    };

    return (
      <>
        {isInRole(['enerpo_admin']) ?
            <></>
        : <></>}

        <Row>
            <Col span={24} style={{ paddingTop: 10 }}>
                <Card bordered={true} bodyStyle={{padding: 10}} loading={isLoading}>
                  <Descriptions title={t("page.sensor_consume")+': ' + sensorData.sensorName} size='small' column={1}>
                    <Descriptions.Item label={t("page.branch")}>
                      <Link to={process.env.REACT_APP_ENERPO_URL + "/branch/"+boilerRoomData.id+"/"+branchData.id}> {branchData.name}</Link>
                    </Descriptions.Item>
                    <Descriptions.Item label={t("page.boiler_room")}>
                      <Link to={process.env.REACT_APP_ENERPO_URL + "/boilerRoom/"+boilerRoomData.id}> {boilerRoomData.name}</Link>
                    </Descriptions.Item>
                  </Descriptions>
                </Card>
            </Col>
        </Row>

        <Row gutter={16} style={{ marginTop: 10, marginBottom: 20 }}>
          <Col span={24}>
            <BranchSensorMeasureLogCard sensorData={sensorData} loading={isLoading} />
          </Col>
        </Row>

        <Row gutter={16} style={{ marginTop: 10, marginBottom: 20 }}>
          <Col span={24}>
            <BranchSensorConsumeLogCard sensorData={sensorData} loading={isLoading} />
          </Col>
        </Row>
      </>
    );
};

export default BranchSensor;