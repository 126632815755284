import { useState, useEffect } from 'react';
import { Modal, Form, Divider, Input, Select, Alert } from 'antd';
import { useAxios } from '../../util/AxiosUtil';
import { useKeycloak } from '@react-keycloak/web';
import { useTranslation } from "react-i18next";
import dayjs from 'dayjs';

const DeliveryPointInsert = ({ boilerRoomData, supplierData, setVisible, visible }) => {

  const { i18n, t } = useTranslation();

  const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);
  const { keycloak } = useKeycloak();

  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const [formErrorText, setFormErrorText] = useState();
  const [selectedSupplier, setSelectedSupplier] = useState([]);

  const onSubmit = (formVals) => {

    formVals.id = 0;
    formVals.boilerRoom = boilerRoomData;
    formVals.supplier = selectedSupplier;
    formVals.createdBy = keycloak.idTokenParsed.preferred_username;
    formVals.createdOn = dayjs();
    formVals.modifiedBy = keycloak.idTokenParsed.preferred_username;
    formVals.modifiedOn = dayjs();

    setIsLoading(true);
    axiosClient.post(process.env.REACT_APP_ENERPO_DATA_PATH+'/deliveryPoint', formVals)
    .then(response => {
      form.resetFields();
      
      setIsLoading(false);
      setVisible('refresh');
    }).catch(error => {
      setIsLoading(false);
      console.error('There was an error!', error.response.data);
      setFormErrorText(error.response.data.errors);
    });
  };

  const changeSupplier = (val, supplierData) => {
    setSelectedSupplier({id : supplierData.key, name : supplierData.value});
  };

  const onClose = () => {
    setVisible(false);
  };

  const layout = {
    labelCol: { span: 6, },
    wrapperCol: { span: 12,},
  };

  return (
    <Modal 
      title={t("page.create_delivery_point")}
      visible={visible}
      confirmLoading={isLoading}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            onSubmit(values);
          })
          .catch(info => {
            console.log('Validate Failed:', info);
          });
      }}
      onCancel={onClose}
    >
      {formErrorText ? (
        <Alert message={formErrorText} type="error" />
      ) : null}
        <Divider />
        <Form {...layout} form={form} onFinish={onSubmit}>
          <Form.Item name="deliveryPointNumber" label={t("page.delivery_point")} rules={[{required: true, message: t("page.select.delivery_point")}]}>
            <Input />
          </Form.Item>
          <Form.Item name="supplier" label={t("page.supplier")} rules={[{required: true, message: t("page.select.supplier")}]}>
            <Select onChange={changeSupplier}>
              <Option value=""></Option>
              {supplierData.map((option) => (
                <Select.Option key={option.id} value={option.name}>{option.name}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="name" label={t("page.name")} rules={[{required: true, message: t("page.select.name")}]}>
            <Input />
          </Form.Item>
        </Form>
    </Modal>
  );
};

export default DeliveryPointInsert;