import { useState } from 'react';
import { Row, Col, Modal, message, Upload, Divider, Button, Select, Typography } from 'antd';
import { useTranslation } from "react-i18next";
import { InboxOutlined } from '@ant-design/icons';
import { useKeycloak } from '@react-keycloak/web';

const InvoiceImport = ({ supplierData, boilerRoomData, setVisible, visible }) => {

  const { i18n, t } = useTranslation();
  const { Dragger } = Upload;
  const { keycloak } = useKeycloak();
  const { Paragraph } = Typography;

  const [selectedSupplier, setSelectedSupplier] = useState([]);
  const [fList, setFList] = useState([]);

  message.config({
    top: 50,
    duration: 10,
    maxCount: 10,
  });

  const props = {
    name: 'file',
    accept: '.xml',
    multiple: true,
    action: process.env.REACT_APP_MCS_API_GATEWAY_URL + process.env.REACT_APP_ENERPO_DATA_PATH+'/invoice/upload/'+selectedSupplier.id+'/'+boilerRoomData.id,
    headers: {
      authorization: 'Bearer ' + keycloak.token,
    },
    maxCount: 10,
    fileList:fList,
    beforeUpload: (file, fileList) => {
      setFList([]);

      if (selectedSupplier === undefined) {
        message.error(t("page.select.supplier"));
        return false;
      }

      const isXML = file.type === 'text/xml';
      if (!isXML) {
        message.error(t("page.file_import_bad_type", {file: `${file.name}`}));
      }
      return isXML || Upload.LIST_IGNORE;
    },
    onChange(info) {
      const { status } = info.file;
      if (status === 'uploading') {
        const fileFound = fList.some(el => el.name === info.file.name);
        if (!fileFound) {
            let newFileItem = {
                uid: info.file.uid,
                name: info.file.name,
                status: info.file.status,
                percent: 0
            };
            setFList(prevState => ([...prevState, newFileItem]));
        }
      } else if (status === 'done') {
        const updatedFileList = fList.map(obj => {
          if (obj.name === info.file.name){
            return {...obj, status: info.file.status, percent: info.file.percent}
          } else {
            return {...obj}
          }
        })

        setFList(updatedFileList);
        message.success(t("page.file_import_sucessfully", {file: `${info.file.name}`}));
      } else if (status === 'error') {
        const updatedFileList = fList.map(obj => {
          if (obj.name === info.file.name){
            return {...obj, status: info.file.status, percent: info.file.percent}
          } else {
            return {...obj}
          }
        })

        setFList(updatedFileList);
        message.error(`${info.file.name} - ` + info.file.response.errors);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
    showUploadList: {
      showDownloadIcon: false,
      showRemoveIcon: false
    },
  };

  const changeSupplier = (val, supplierData) => {
    setSelectedSupplier({id : supplierData.key, name : supplierData.value});
  };

  const onClose = () => {
    setFList([]);
    setVisible(false);
  };

  return (
    <Modal 
      title={t("page.import_invoice_title")}
      visible={visible}
      onCancel={onClose}
      footer={[
        <Button onClick={onClose}>{t("page.close")}</Button>
      ]}
    >
        <Divider />
        <Row>
          <Col span={4}>
            <Paragraph style={{ padding: 5}}>{t("page.supplier") + ":"}</Paragraph>
          </Col>
          <Col span={20}>
            <Select onChange={changeSupplier} style={{width: '100%'}}>
              <Option value=""></Option>
              {supplierData.map((option) => (
                <Select.Option key={option.id} value={option.name}>{option.name}</Select.Option>
              ))}
            </Select>
          </Col>
        </Row>

        { selectedSupplier.id ?
          <Dragger {...props}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">{t("page.import_select_files")}</p>
            <p className="ant-upload-hint">{t("page.import_max_files")}</p>
          </Dragger>
        : <></>}
    </Modal>
  );
};

export default InvoiceImport;