import ControlTab from "./control/tn_kratka/ControlTab";
import ImageTab from "./image/ImageTab";

import tn_28oct_scheme from './image/tn_28oct/scheme.jpg';
import tn_28oct_mapczt from './image/tn_28oct/mapczt.jpg';

const BoilerRoomTab = ({ boilerRoomTabId, boilerRoomId, unit, refresh, dateRange, changeDateRange }) => {

  return (
    <>
      {boilerRoomTabId === 7 ? 
        <ControlTab boilerRoomId={boilerRoomId} unit={unit} refresh={refresh} />
      : boilerRoomTabId === 15 ? 
        <ImageTab image={tn_28oct_scheme}/>
      : boilerRoomTabId === 16 ? 
        <ImageTab image={tn_28oct_mapczt}/>
      : <></>
      }
    </>
  );
};

export default BoilerRoomTab;