import { useState, useEffect } from 'react';
import { Card, Row, Col, Typography, Space, Breadcrumb } from 'antd';
import { useParams, Link } from "react-router-dom";
import { useAxios } from '../../util/AxiosUtil';
import { useTranslation } from "react-i18next";

import isInRole from '../../security/IsInRole';
import ThermoSensorMeasureLogCard from './ThermoSensorMeasureLogCard';

import { HomeOutlined } from '@ant-design/icons';

const ThermoSensor = () => {
    
    let params = useParams();
    const { Title } = Typography;
    const { i18n, t } = useTranslation();
    const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);
    
    const [sensorData, setSensorData] = useState([]);
    const [boilerRoomData, setBoilerRoomData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      customFetch();
    }, [params.id]);

    const customFetch = async () => {
      setIsLoading(true);
      await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/thermoSensor/'+params.boilerRoomId+'/'+params.sensorId)
      .then(response => {
        setSensorData(response.data);
        setBoilerRoomData(response.data.boilerRoom);
        setIsLoading(false);
      }).catch('error: ' + console.log);
    };

    return (
      <>
        {isInRole(['enerpo_admin']) ?
            <></>
        : <></>}

        <Breadcrumb
          items={[
            { title: <Link to={process.env.REACT_APP_ENERPO_URL + "/company/"+boilerRoomData.companyId}><HomeOutlined /> {t("page.company")}</Link>, },
            { title: <Link to={process.env.REACT_APP_ENERPO_URL + "/boilerRoom/"+boilerRoomData.id}>{t("page.boiler_room")}</Link>, },
            { title: t("page.sensor_thermo") },
          ]}
        />

        <Row>
            <Col span={24} style={{ paddingTop: 10 }}>
              <Card bordered={true} styles={{body : {padding: 10}}} loading={isLoading}>
                <Space style={{ paddingLeft: 2 }}>
                  <Title level={5}>
                    {t("page.sensor_thermo") + ": " + sensorData.sensorName + " - " + boilerRoomData.name}
                  </Title>
                </Space>
              </Card>
            </Col>
        </Row>
        
        <Row gutter={16} style={{ marginTop: 10, marginBottom: 20 }}>
          <Col span={24}>
            <ThermoSensorMeasureLogCard sensorData={sensorData} loading={isLoading} />
          </Col>
        </Row>

      </>
    );
};

export default ThermoSensor;