import { useState, useEffect } from 'react';
import { Spin } from 'antd';
import { G2, Line } from '@ant-design/plots';
import { useAxios } from '../../../util/AxiosUtil';
import { useTranslation } from "react-i18next";
import { getStep, getDateFormat } from '../../../util/ChartUtil';
import dayjs from 'dayjs';

const CopTinyChart = ({ boilerRoomId }) => {

  const { i18n, t } = useTranslation();
  const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);

  const [chartData, setChartData] = useState(0);  
  const [isLoading, setIsLoading] = useState(false);
  const [groupBy, setGroupBy] = useState("hour");
  const [dateRange, setDateRange] = useState([dayjs(), dayjs()]);

  useEffect(() => {
    customFetch(dateRange[0], dateRange[1]);
  }, [boilerRoomId]);

  const customFetch = async (dateFrom, dateTo) => {
    setIsLoading(true);

    await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/chartData/'+boilerRoomId+'/BOILER_ROOM/cop/hour/'+dateFrom.format(getDateFormat(groupBy))+'/'+dateTo.add(2,getStep(groupBy)).format(getDateFormat(groupBy)))
    .then(response => {

      var myJson = [];
      for (var k = 0; k < response.data.length; k++) {
        if (response.data[k].value !== null && dayjs(response.data[k].date) < dayjs()) {
          myJson.push({
            type: "COP",
            value: response.data[k].value,
            date: dayjs(response.data[k].date).format("DD.MM.YYYY HH")+":00"
          });
        }
      }

      setChartData(myJson);
      setIsLoading(false);
    }).catch('error: ' + console.log);
  };

  G2.registerShape('point', 'breath-point', {
    draw(cfg, container) {
      const data = cfg.data;
      const point = {
        x: cfg.x,
        y: cfg.y,
      };
      const group = container.addGroup();
      const max = chartData.reduce((prev, current) => (prev && prev.value > current.value) ? prev : current);
      const maxCnt = chartData.reduce(function(n, x) {
        return n + (x.value === max.value);
      }, 0);

      if (data.value === max.value && maxCnt === 1) {
        const decorator1 = group.addShape('circle', {
          attrs: {
            x: point.x,
            y: point.y,
            r: 10,
            fill: cfg.color,
            opacity: 0.5,
          },
        });
        decorator1.animate(
          {
            r: 20,
            opacity: 0,
          },
          {
            duration: 1800,
            easing: 'easeLinear',
            repeat: true,
          },
        );
      }

      return group;
    },
  });

  const config = {
    data: chartData,
    padding: 'auto',
    xField: 'date',
    yField: 'value',
    height: 150,
    color: '#0ebf13',
    tooltip: {
      formatter: (x) => {
        return { name: x.value > 3 ? t("page.cop") : t("page.low_cop"), value: parseFloat(x.value).toFixed(3) };
      },
    },
    point: {
      shape: 'breath-point',
    },
    annotations: [
      {
        type: 'regionFilter',
        start: ['min', '3'],
        end: ['max', '-10'],
        color: '#F4664A',
      },{
        type: 'line',
        start: ['min', '3'],
        end: ['max', '3'],
        style: {
          stroke: '#5B8FF9',
          lineDash: [2, 2],
        },
      },
    ],
    xAxis: {
      label: false
    }
  };

  return (
    <Spin spinning={isLoading}>
      <Line {...config} loading={isLoading}/>
    </Spin>
  );
};

export default CopTinyChart;