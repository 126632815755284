import L from 'leaflet';
import marker from "../../static/img/marker.png";

const MapMarker = new L.Icon({
    iconUrl: marker,
    iconRetinaUrl: marker,
    iconAnchor: [5,55],
    popupAnchor: [10,-44],
    iconSize: [,30]
});

export { MapMarker };
export default MapMarker;