import { useState, useEffect } from 'react';
import { useAxios } from '../../util/AxiosUtil.js';
import { useTranslation } from "react-i18next";
import { Button, Table, Modal, Divider, Input, Space } from 'antd';
import { SearchOutlined, CloseOutlined } from '@ant-design/icons';

import dayjs from "dayjs";

const InvoiceTable = ({ dpId, setVisible, visible }) => {

  const { i18n, t } = useTranslation();

  const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);

  const dateFormat = "DD.MM.YYYY";
  const dateTimeFormat = "DD.MM.YYYY HH:mm:ss";

  const [limit, setLimit] = useState(5);
  const [pagination, setPagination] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [invoiceListData, setInvoiceListData] = useState([]);

  useEffect(() => {
    if (visible)
      customFetch({offset: 1, limit: limit, orderBy: 'createdOn', order: 'descend'});
  }, [dpId, visible]);

  const customFetch = async (searchCriteria = {}) => {
    
    setIsLoading(true);
    await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/invoice?deliveryPointId='+dpId, {
      params: searchCriteria
    }).then(response => {
      setInvoiceListData(response.data.content);
      setPagination({current: searchCriteria.offset, pageSize: searchCriteria.limit, orderBy: 'createdOn', order: 'descend', total: response.data.totalElements});
      setIsLoading(false);
    }).catch('error: ' + console.log);
  };

  const onClose = () => {
    setVisible(false);
  };

  const handleTableChange = (
    pagination,
    filters,
    sorter
  ) => {
    if (sorter.columnKey === undefined)
      sorter.columnKey = pagination.orderBy;

    customFetch({
      offset: pagination.current,
      limit: pagination.pageSize,
      orderBy: sorter.columnKey,
      order: sorter.order,
      ...formData
    });
  };

  const onSearch = (searchKey) => {
    var search = {search: searchKey+":"+searchValue};
    customFetch({
      offset: 1,
      limit: limit,
      orderBy: 'createdOn',
      ...search
    });
  };

  function getColumnSearchProps(dataIndex) {
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={t("page.search")}
            onChange={e => setSearchValue(e.target.value ? [e.target.value] : [])}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => onSearch(dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              {t("page.search")}
            </Button>
            <Button
              onClick={() => {
                confirm({ closeDropdown: true });
              }}
              icon={<CloseOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              {t("page.close")}
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    }
  }

  const columns = [
    {
      title: t("page.invoice_number"),
      dataIndex: 'invoiceNumber',
      key: 'invoiceNumber',
      sorter: (a, b) => a.invoiceNumber - b.invoiceNumber,
      sortDirections: ['ascend', 'descend', 'ascend'],
      ...getColumnSearchProps('invoiceNumber'),
    },{
      title: t("page.invoice_period"),
      dataIndex: 'periodFrom',
      key: 'periodFrom',
      render: (text, record, index) => dayjs(record.periodFrom).format(dateFormat) + ' - ' + dayjs(record.periodTo).format(dateFormat),
    },{
      title: t("page.total_price"),
      dataIndex: 'totalPrice',
      key: 'totalPrice'
    },{
      title: t("page.created_by"),
      dataIndex: 'createdBy',
      key: 'createdBy',
      sorter: (a, b) => a.createdBy - b.createdBy,
      sortDirections: ['ascend', 'descend', 'ascend'],
      responsive: ["xl"]
    },{
      title: t("page.created_on"),
      dataIndex: 'createdOn',
      key: 'createdOn',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.createdOn - b.createdOn,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record, index) => record.createdOn ? dayjs(record.createdOn).format(dateTimeFormat) : null,
      responsive: ["xl"]
    }
  ];

  return (
    <>
      <Modal 
        title={t("page.invoices") + ": "}
        visible={visible}
        onCancel={onClose}
        width={900}
        footer={[
          <Button onClick={onClose}>{t("page.close")}</Button>
        ]}
      >
          <Divider />
          <Table
            rowKey={invoiceListData => invoiceListData.id}
            columns={columns}
            dataSource={invoiceListData}
            pagination={pagination}
            onChange={handleTableChange}
            loading={isLoading}
            size="small"
          />
      </Modal>
    </>
  );
};

export default InvoiceTable;