import { useState, useEffect } from 'react';
import { Spin } from 'antd';
import { RingProgress } from '@ant-design/plots';
import { useAxios } from '../../../util/AxiosUtil';
import { useTranslation } from "react-i18next";

const BatteryChargeStateChart = ({ batteryId }) => {

  const { i18n, t } = useTranslation();
  const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);

  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    customFetch();
  }, [batteryId]);

  const customFetch = async () => {
    setIsLoading(true);
    await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/battery/'+batteryId+'/chargeState')
    .then(response => {
      setData(response.data);
      setIsLoading(false);
    }).catch('error: ' + console.log);
  };

  const config = {
    height: 150,
    width: 130,
    autoFit: true,
    percent: data/100,
    color: ['#ff9f0a', '#E8EDF3'],
    innerRadius: 0.85,
    radius: 0.98,
    statistic: {
      title: {
        style: {
          color: '#363636',
          fontSize: '12px',
          lineHeight: '14px',
        },
        formatter: () => t("page.charge_state"),
      },
    },
  };

  return (
    <Spin spinning={isLoading}>
      <RingProgress {...config} />
    </Spin>
  );
};

export default BatteryChargeStateChart;