import { useState, useEffect } from 'react';
import { Row, Col, DatePicker, Button, Tooltip, Empty, Spin } from 'antd';
import { useAxios } from '../../../util/AxiosUtil';
import { Column } from '@ant-design/charts';
import { useTranslation } from "react-i18next";
import { LeftOutlined, RightOutlined, SyncOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

const BoilerRoomSensorConsumeLogChart = ({ sensorId, setRefresh, refresh }) => {

  const dateFormat = "YYYY-MM-DD";
  const dateTimeFromat = "DD.MM.YYYY HH:mm";

  const { i18n, t } = useTranslation();
  const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(1);
  const [graphData, setGraphData] = useState([]);
  const [emptyGraphData, setEmptyGraphData] = useState(false);
  const [currentDate, setCurrentDate] = useState(dayjs().format(dateFormat));
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    customFetch({offset: offset, limit: limit, orderBy: 'createdOn', order: 'descend'});
  }, [sensorId]);

  useEffect(() => {
    if (refresh === true)
      customFetch({offset: offset, limit: limit, orderBy: 'createdOn', order: 'descend'});
    
    setRefresh(false);
  }, [refresh]);

  const customFetch = async (searchCriteria = {}) => {
      setIsLoading(true);
      await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/boilerRoomConsumeLog/'+sensorId, {
        params: searchCriteria
      }).then(response => {

        setEmptyGraphData(false);
        if (response.data.content.length === 0)
          setEmptyGraphData(true);

        response.data.content.sort(function(a,b){
          var dateA = new Date(a.createdOn).getTime();
          var dateB = new Date(b.createdOn).getTime();
          return dateA > dateB ? 1 : -1;  
        });

        var myJson = [];
        for (var k = 0; k < response.data.content.length; k++) {
          myJson.push({
            unit: response.data.content[k].unit,
            value: parseFloat(response.data.content[k].consume),
            createdOn: dayjs(response.data.content[k].createdOn).format(dateTimeFromat)
          });
        }

        setGraphData(myJson);
        setIsLoading(false);
      }).catch('error: ' + console.log);
  };

  function onDateChange(date) {
    var changedDate = dayjs(date).format(dateFormat);
    if (date === null)
      changedDate = dayjs().format(dateFormat);
    
    setCurrentDate(changedDate);
    customFetch({offset: 1, limit: limit, orderBy: 'createdOn', order: 'descend', createdOn: changedDate});
  }

  const prevStep = () => {
    setOffset(offset+1);
    customFetch({offset: offset+1, limit: limit, orderBy: 'createdOn', order: 'descend', createdOn: currentDate});
  }
  
  const nextStep = () => {
    if (offset > 1) {
      setOffset(offset-1);
      customFetch({offset: offset-1, limit: limit, orderBy: 'createdOn', order: 'descend', createdOn: currentDate});
    } else {
      customFetch({offset: offset, limit: limit, orderBy: 'createdOn', order: 'descend', createdOn: currentDate});
    }
  }

  const refreshChart = () => {
    customFetch({offset: offset, limit: limit, orderBy: 'createdOn', order: 'descend', createdOn: currentDate});
  }

  const config = {
    data: graphData,
    xField: 'createdOn',
    yField: 'value',
    height: 200,
    seriesField: 'unit',
    stack: true,
    tooltip: {
      formatter: (x) => {
        console.log(x);
        return { name: t("page.measured_value"), value: ''.concat(parseFloat(x.value).toFixed(2), ' ' + x.unit) };
      },
    },
  };

  return (
    <Spin spinning={isLoading}>    
      <Row style={{ paddingBottom: 20 }}>
        <Col flex={3}>
          <Row>
            <Col>
              <DatePicker value={dayjs(currentDate, dateFormat)} onChange={onDateChange} format="DD.MM.YYYY" />
            </Col>
          </Row>
        </Col>
        <Col flex={2}>
          <Row justify="end">
            <Col style={{ paddingRight: 5 }}>
              <Tooltip title={t("page.previous")} style={{ margin: 15 }} >
                <Button type="dashes" shape="circle" icon={<LeftOutlined />} onClick={prevStep} />
              </Tooltip>
            </Col>
            <Col style={{ paddingRight: 5 }}>
              <Tooltip title={t("page.next")} style={{ margin: 15 }} >
                <Button type="dashes" shape="circle" icon={<RightOutlined />} onClick={nextStep} />
              </Tooltip>
            </Col>
            <Col style={{ paddingRight: 5 }}>
              <Button type="dashes" shape="circle" icon={<SyncOutlined />} onClick={refreshChart} />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          {emptyGraphData ?
            <Empty />
          :
            <Column {...config} loading={isLoading} />}
        </Col>
      </Row>
    </Spin>
  );
};

export default BoilerRoomSensorConsumeLogChart;