import { useState, useEffect } from 'react';
import { useAxios } from '../../util/AxiosUtil.js';
import { Table } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import InvoiceTable from '../invoice/InvoiceTable.js';

import dayjs from "dayjs";

const DeliveryPointTable = ({ boilerRoomData, setRefresh, refresh }) => {

  const { i18n, t } = useTranslation();

  const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);

  const dateTimeFormat = "DD.MM.YYYY HH:mm:ss";

  const [isLoading, setIsLoading] = useState(false);
  const [deliveryPointData, setDeliveryPointData] = useState([]);
  const [limit, setLimit] = useState(5);
  const [pagination, setPagination] = useState({});
  const [formData, setFormData] = useState({});
  const [selectedDpId, setSelectedDpId] = useState();
  const [visibleInvoiceTable, setVisibleInvoiceTable] = useState(false);

  useEffect(() => {
      customFetch({offset: 1, limit: limit, orderBy: 'createdOn', order: 'descend'});
  }, []);
  
  useEffect(() => {
    if (refresh === true)
      customFetch({offset: 1, limit: limit, orderBy: 'createdOn', order: 'descend'});
    
    setRefresh(false);
  }, [refresh]);

  const customFetch = async (searchCriteria = {}) => {
    
    setIsLoading(true);
    await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/deliveryPoint?boilerRoomId='+boilerRoomData.id, {
      params: searchCriteria
    }).then(response => {
      setDeliveryPointData(response.data.content);
      setPagination({current: searchCriteria.offset, pageSize: searchCriteria.limit, orderBy: 'createdOn', order: 'descend', total: response.data.totalElements});
      setIsLoading(false);
    }).catch('error: ' + console.log);
  };

  const openInvoiceTable = (record) => {
    setVisibleInvoiceTable(true);
    setSelectedDpId(record.id);
  };

  const handleTableChange = (
      pagination,
      filters,
      sorter
    ) => {
      if (sorter.columnKey === undefined)
        sorter.columnKey = pagination.orderBy;

      customFetch({
        offset: pagination.current,
        limit: pagination.pageSize,
        orderBy: sorter.columnKey,
        order: sorter.order,
        ...formData
      });
  };

  const columns = [
    {
      title: t("page.delivery_point"),
      dataIndex: 'deliveryPointNumber',
      key: 'deliveryPointNumber',
      sorter: (a, b) => a.deliveryPointNumber - b.deliveryPointNumber,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },{
      title: t("page.supplier"),
      dataIndex: 'supplier',
      key: 'supplier',
      sorter: (a, b) => a.supplier - b.supplier,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record, index) => record.supplier.name
    },{
      title: t("page.name"),
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name - b.name,
      sortDirections: ['ascend', 'descend', 'ascend'],
      responsive: ["xl"]
    },{
      title: t("page.created_by"),
      dataIndex: 'createdBy',
      key: 'createdBy',
      sorter: (a, b) => a.createdBy - b.createdBy,
      sortDirections: ['ascend', 'descend', 'ascend'],
      responsive: ["xl"]
    },{
      title: t("page.created_on"),
      dataIndex: 'createdOn',
      key: 'createdOn',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.createdOn - b.createdOn,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record, index) => record.createdOn ? dayjs(record.createdOn).format(dateTimeFormat) : null,
      responsive: ["xl"]
    },{
      title: t("page.modified_by"),
      dataIndex: 'modifiedBy',
      key: 'modifiedBy',
      sorter: (a, b) => a.modifiedBy - b.modifiedBy,
      sortDirections: ['ascend', 'descend', 'ascend'],
      responsive: ["xl"]
    },{
      title: t("page.modified_on"),
      dataIndex: 'modifiedOn',
      key: 'modifiedOn',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.modifiedOn - b.modifiedOn,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record, index) => record.modifiedOn ? dayjs(record.modifiedOn).format(dateTimeFormat) : null,
      responsive: ["xl"]
    },{
      title: t("page.action"),
      key: 'operation',
      render: (text, record, index) => 
      <div>
        <div>
          <Link to="" onClick={() => openInvoiceTable(record)}>{t("page.show")}</Link>
        </div>
      </div>
    },
  ];

    return (
      <>
        <Table
          rowKey={deliveryPointData => deliveryPointData.id}
          columns={columns}
          dataSource={deliveryPointData}
          pagination={pagination}
          onChange={handleTableChange}
          loading={isLoading}
          size="small"
        />
        <InvoiceTable dpId={selectedDpId} setVisible={setVisibleInvoiceTable} visible={visibleInvoiceTable} />
      </>
    );
};

export default DeliveryPointTable;