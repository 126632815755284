import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAxios } from '../../util/AxiosUtil';
import { Button, Space, Input, Table } from 'antd';
import { SearchOutlined, CloseOutlined } from '@ant-design/icons';

import { useTranslation } from "react-i18next";

const CompanyTable = ({ setRefresh, refresh }) => {

  const { i18n, t } = useTranslation();

  const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);

  const dateFromat = "DD.MM.YYYY HH:mm:ss";
  const [isLoading, setIsLoading] = useState(false);
  const [companyListData, setCompanyListData] = useState([]);
  const [limit, setLimit] = useState(5);
  const [pagination, setPagination] = useState({});
  const [formData, setFormData] = useState({});
  const [searchValue, setSearchValue] = useState();

  useEffect(() => {
      customFetch({offset: 1, limit: limit, orderBy: 'createdOn', order: 'ascend'});
  }, []);
  
  useEffect(() => {
    if (refresh === true)
      customFetch({offset: 1, limit: limit, orderBy: 'createdOn', order: 'ascend'});
    
    setRefresh(false);
  }, [refresh]);

  const customFetch = async (searchCriteria = {}) => {

    setIsLoading(true);
    await axiosClient.get(process.env.REACT_APP_ENEGRAF_DATA_PATH+'/company', {
      params: searchCriteria
    }).then(response => {
      setCompanyListData(response.data.content);
      setPagination({current: searchCriteria.offset, pageSize: searchCriteria.limit, orderBy: 'createdOn', order: 'ascend', total: response.data.totalElements, pageSizeOptions: ["5","10","25","50"], showSizeChanger: response.data.totalElements <= 5 ? false : true});
      setIsLoading(false);
    }).catch('error: ' + console.log);
  };

  const handleTableChange = (
      pagination,
      filters,
      sorter
    ) => {
      if (sorter.columnKey === undefined) {
        sorter.columnKey = pagination.orderBy;
        sorter.order = pagination.order;
      }

      customFetch({
        offset: pagination.current,
        limit: pagination.pageSize,
        orderBy: sorter.columnKey,
        order: sorter.order,
        ...formData
      });
  };

  const onSearch = (searchKey) => {
    var search = {search: searchKey+":"+searchValue};
    customFetch({
      offset: 1,
      limit: limit,
      orderBy: 'createdOn',
      ...search
    });
  };

  function getColumnSearchProps(dataIndex) {
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={t("page.search")}
            onChange={e => setSearchValue(e.target.value ? [e.target.value] : [])}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => onSearch(dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              {t("page.search")}
            </Button>
            <Button
              onClick={() => {
                confirm({ closeDropdown: true });
              }}
              icon={<CloseOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              {t("page.close")}
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    }
  }

  const columns = [
      {
        title: t("page.name"),
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => a.name - b.name,
        sortDirections: ['ascend', 'descend', 'ascend'],
        ...getColumnSearchProps('name'),
        render: (text, record, index) => 
        <div>
          <div>
            <Link to={process.env.REACT_APP_ENERPO_URL + "/company/"+record.id}>{record.name}</Link>
          </div>
        </div>
      },{
        title: t("page.town"),
        dataIndex: 'town',
        sorter: (a, b) => a.town - b.town,
        sortDirections: ['ascend', 'descend', 'ascend'],
        key: 'town',
        ...getColumnSearchProps('town'),
        responsive: ["lg"]
      },{
        title: t("page.street"),
        dataIndex: 'street',
        key: 'street',
        sorter: (a, b) => a.street - b.street,
        sortDirections: ['ascend', 'descend', 'ascend'],
        ...getColumnSearchProps('street'),
        responsive: ["lg"]
      }
    ];

    return (
      <Table
        rowKey={companyListData => companyListData.id}
        columns={columns}
        dataSource={companyListData}
        pagination={pagination}
        onChange={handleTableChange}
        loading={isLoading}
        size="small"
      />
    );
};

export default CompanyTable;