import { useState, useEffect } from 'react';
import { Modal, Form, DatePicker, Alert, Divider, Select } from 'antd';
import { useAxios } from '../../util/AxiosUtil';
import { useKeycloak } from '@react-keycloak/web';
import { useTranslation } from "react-i18next";
import dayjs from 'dayjs';

const CostPlanInsert = ({ boilerRoomData = {}, costPlanTypeData, setVisible, visible }) => {

    const { i18n, t } = useTranslation();

    const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);
    const { keycloak } = useKeycloak();

    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();
    const [formErrorText, setFormErrorText] = useState();
    const [selectedCostPlanType, setSelectedCostPlanType] = useState([]);

    const onSubmit = (formVals) => {

      formVals.id = 0;
      formVals.boilerRoom = boilerRoomData;
      formVals.costPlanType = selectedCostPlanType;
      formVals.year = formVals.year.format("YYYY");
      formVals.createdBy = keycloak.idTokenParsed.preferred_username;
      formVals.createdOn = dayjs();
      formVals.modifiedBy = keycloak.idTokenParsed.preferred_username;
      formVals.modifiedOn = dayjs();

      setIsLoading(true);
      axiosClient.post(process.env.REACT_APP_ENERPO_DATA_PATH+'/costPlan', formVals)
      .then(response => {
        form.resetFields();
        
        setIsLoading(false);
        setVisible('refresh');
      }).catch(error => {
        setIsLoading(false);
        console.error('There was an error!', error.response.data);
        setFormErrorText(error.response.data.errors);
      });
    };

    const onClose = () => {
      setVisible(false);
    };

    const changeCostPlanType = (val, costPlanType) => {
      setSelectedCostPlanType({id : costPlanType.key, name : costPlanType.value});
    };

    const layout = {
      labelCol: { span: 6, },
      wrapperCol: { span: 12,},
    };

    return (
      <Modal s
        title={t("page.cost_plan_create")} 
        visible={visible} 
        confirmLoading={isLoading}
        onOk={() => {
          form
            .validateFields()
            .then(values => {
              onSubmit(values);
            })
            .catch(info => {
              console.log('Validate Failed:', info);
            });
        }}
        onCancel={onClose}
      >
        {formErrorText ? (
          <Alert message={formErrorText} type="error" />
        ) : null}
          <Divider />
          <Form {...layout} form={form} onFinish={onSubmit} initialValues={{ year: dayjs() }}>
            <Form.Item name="year" label={t("page.year")} rules={[{required: true, message: t("page.select.year")}]}>
              <DatePicker picker="year" style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item name="costPlan" label={t("page.cost_plan")} rules={[{required: true, message: t("page.select.cost_plan")}]}>
              <Select onChange={changeCostPlanType}>
                <Option value=""></Option>
                {costPlanTypeData.map((option) => (
                  <Select.Option key={option.id} value={option.name}>{option.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
      </Modal>
    );
};

export default CostPlanInsert;