import { Card, Row, Col } from 'antd';
import Forecast from './../template/weather/Forecast';
import History from './../template/weather/History';
import dayjs from 'dayjs';

export const getWeather = (boilerRoomId, isForecast) => {

  return !isForecast ?
    <Row gutter={10}>
      <Col xs={0} sm={6} md={6} lg={6} xl={0} xxl={6}>
        <Card size="small" style={{width: '100%', height: 150}}>
          <History boilerRoomId={boilerRoomId} day={dayjs().subtract(3, 'd')} />
        </Card>
      </Col>
      <Col xs={0} sm={6} md={6} lg={6} xl={6} xxl={6}>
        <Card size="small" style={{width: '100%', height: 150}}>
          <History boilerRoomId={boilerRoomId} day={dayjs().subtract(2, 'd')} />
        </Card>
      </Col>
      <Col flex="auto" style={{display: 'flex'}} >
        <Card size="small" style={{width: '100%', height: 150}}>
          <History boilerRoomId={boilerRoomId} day={dayjs().subtract(1, 'd')} />
        </Card>
      </Col>
      <Col flex={2} style={{display: 'flex'}} >
        <Card size="small" style={{width: '100%', height: 150}}>
          <History boilerRoomId={boilerRoomId} day={dayjs()} />
        </Card>
      </Col>
    </Row>
  :
    <Row gutter={10}>
      <Col flex={2} style={{display: 'flex'}} >
        <Card size="small" style={{width: '100%'}}>
          <Forecast boilerRoomId={boilerRoomId} day={dayjs()} />
        </Card>
      </Col>
      <Col flex="auto" style={{display: 'flex'}} >
        <Card size="small" style={{width: '100%'}}>
          <Forecast boilerRoomId={boilerRoomId} day={dayjs().add(1, 'd')} />
        </Card>
      </Col>
      <Col xs={0} sm={6} md={6} lg={6} xl={6} xxl={6}>
        <Card size="small" style={{width: '100%'}}>
          <Forecast boilerRoomId={boilerRoomId} day={dayjs().add(2, 'd')} />
        </Card>
      </Col>
      <Col xs={0} sm={6} md={6} lg={6} xl={0} xxl={6}>
        <Card size="small" style={{width: '100%'}}>
          <Forecast boilerRoomId={boilerRoomId} day={dayjs().add(3, 'd')} />
        </Card>
      </Col>
    </Row>
}
