import { useState, useEffect } from 'react';
import { Spin, Avatar, Typography } from 'antd';
import { useAxios } from '../../../util/AxiosUtil';
import { useTranslation } from "react-i18next";

import ChartCard from '../../../util/ChartCard';
import { ToolOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

const FweTotalProductionCard = ({ fweId }) => {

  const { i18n, t } = useTranslation();
  const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);
  
  const { Text } = Typography;
  const [data, setData] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 700);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    customFetch();
  }, [fweId]);

  useEffect(() => {
    function handleResize() {
        setIsMobile(window.innerWidth < 700)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, []);

  const customFetch = async () => {
      setIsLoading(true);
      await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/fwe/'+fweId+'/totalProduction')
      .then(response => {
        setData(parseFloat(response.data).toFixed(2).toString());
        setIsLoading(false);
      }).catch('error: ' + console.log);
  };

  return (
    <Spin spinning={isLoading}>
      <ChartCard
          bordered={false}
          avatar={<Avatar style={{ backgroundColor: '#fff', borderColor: '#1677ff', color: '#1677ff' }} shape="square" size={55} icon={<ToolOutlined />} />}
          title={<Text style={{ fontSize: 16 }}>{t("page.production") + " " + dayjs().format("YYYY")}</Text>}
          loading={isLoading}
          total={<Text style={{ fontSize: 18, fontWeight: 600 }}>{data.toLocaleString('en-US') + ' kWh'}</Text>}
      />
    </Spin>
  );
};

export default FweTotalProductionCard;