import { useState, useEffect } from 'react';
import { DatePicker, Empty, Spin, Tooltip, Button, Card, Space, Select, Row, Col, Table } from 'antd';
import { useAxios } from '../../../../util/AxiosUtil';
import dayjs from 'dayjs';
import { Line, Column } from '@ant-design/plots';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { useTranslation } from "react-i18next";
import { getStep, getChartUnit, getDateFormat, getDispDateFormat, getMinutesPart, getDateRange, disabled3MonthsDate, disabled12MonthsDate } from '../../../../util/ChartUtil';

import { LeftOutlined, RightOutlined, SyncOutlined, DownloadOutlined } from '@ant-design/icons';

const SingleSourceTab = ({ boilerRoomId, chart, chartUnit, chartLabel, unit, refresh, dateRange, changeDateRange, groupBy, changeGroupBy }) => {
    
    const { i18n, t } = useTranslation();
    const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);
    const { RangePicker } = DatePicker;

    const [isMobile, setIsMobile] = useState(window.innerWidth < 700);
    const [graphData, setGraphData] = useState([]);
    const [emptyGraphData, setEmptyGraphData] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [graphType, setGraphType] = useState("column");

    useEffect(() => {
      if (dateRange !==null)
        fetchData(dateRange[0], dateRange[1]);
    }, [boilerRoomId, dateRange, unit, refresh, groupBy]);
    
    useEffect(() => {
      function handleResize() {
        setIsMobile(window.innerWidth < 700)
      }

      window.addEventListener('resize', handleResize)
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }, []);

    const fetchData = async (dateFrom, dateTo) => {
      setIsLoading(true);
      await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/chartData/'+boilerRoomId+'/BOILER_ROOM/'+chart+'/'+groupBy+'/'+dateFrom.format(getDateFormat(groupBy))+'/'+dateTo.add(1,getStep(groupBy)).format(getDateFormat(groupBy)))
      .then(response => {
        setEmptyGraphData(false);
        if (response.data.length === 0)
          setEmptyGraphData(true);

        var myJson = [];
        for (var k = 0; k < response.data.length; k++) {
          if (response.data[k].value !== null && dayjs(response.data[k].date) < dayjs()) {
            myJson.push({
              type: t(chartLabel),
              value: (chartUnit === 'kWh' && unit === 'MWh') ? response.data[k].value / 1000 : response.data[k].value,
              unit: getChartUnit(unit, chartUnit),
              date: dayjs(response.data[k].date).format(getDispDateFormat(groupBy)) + getMinutesPart(groupBy)
            });
          }
        }

        setGraphData(myJson);
        setIsLoading(false);
      }).catch('error: ' + console.log);
    };

    const prevStep = () => {
      changeDateRange([dayjs(dateRange[0]).subtract(1,getStep(groupBy)), dayjs(dateRange[1]).subtract(1,getStep(groupBy))]);
    }
    
    const nextStep = () => {
      if (!dayjs(dateRange[1]).isSame(dayjs())) {
        changeDateRange([dayjs(dateRange[0]).add(1,getStep(groupBy)), dayjs(dateRange[1]).add(1,getStep(groupBy))]);
      } else {
        fetchData(dateRange[0], dateRange[1]);
      }
    }

    const refreshChart = () => {
      fetchData(dateRange[0], dateRange[1]);
    }

    const config = {
      data: graphData,
      xField: 'date',
      yField: 'value',
      height: 300,
      tooltip: {
        formatter: (x) => {
          return { name: t("page.value"), value: parseFloat(x.value).toFixed(4) + ' ' + getChartUnit(unit, chartUnit) };
        },
      },
      legend: false
    };

    const columns = [
      {
        title: t("page.name"),
        dataIndex: 'type',
        key: 'type',
        width: '75%',
        responsive: ["sm"]
      },{
        title: t("page.value"),
        dataIndex: 'value',
        key: 'value',
        width: '10%',
        render: (text, record, index) => <div>{parseFloat(record.value).toFixed(2)}</div>
      },{
        title: t("page.unit"),
        dataIndex: 'unit',
        key: 'unit',
        width: '5%',
        render: (text, record, index) => <div>{getChartUnit(unit, chartUnit)}</div>
      },{
        title: t("page.date"),
        dataIndex: 'date',
        key: 'date',
        width: '100%',
        align: 'right'
      }
    ]

    const exportToExcel = () => {
      const worksheet = XLSX.utils.json_to_sheet(graphData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  
      // Buffer to store the generated Excel file
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
  
      saveAs(blob, "data.xlsx");
    };

    return (
      <Spin spinning={isLoading}>
        <Card
          size="small" loading={isLoading}
          style={{ marginTop: 10, paddingBottom: 15 }}
          title={
            <>
              <Row gutter={[5,10]}>
                <Col span={3} xs={24} md={3} xl={3} xxl={3}>
                  <Select
                    onChange={changeGroupBy} 
                    defaultValue={{ value: groupBy }}
                    style={{ width: '100%', marginRight: 10 }}
                    options={[ { value: 'hour', label: "1 " + t("page.hour") }, { value: 'day', label: "1 " + t("page.day") }, { value: 'month', label: "1 " + t('page.month') } ]}
                  />
                </Col>
                <Col span={3} xs={24} md={3} xl={3} xxl={3}>
                  <Select 
                    onChange={setGraphType} 
                    defaultValue={{ value: 'column', label: t("page.chart_column") }}
                    style={{ width: '100%', marginRight: 10 }}
                    options={[ { value: 'column', label: t("page.chart_column") }, { value: 'line', label: t("page.chart_line") }, { value: 'table', label: t("page.chart_table") } ]} 
                  />
                </Col>
                <Col span={6} xs={24} md={6} xl={6} xxl={6}>
                  {groupBy === 'hour'?
                    <DatePicker 
                      value={dateRange[0]}
                      onChange={(d) => changeDateRange(getDateRange(d, groupBy))}
                      style={{ width: '100%', marginRight: 10 }}
                      format="DD.MM.YYYY" 
                      maxDate={dayjs()}
                    />
                  : groupBy === 'day' ?
                    <RangePicker 
                      value={dateRange}
                      onChange={(d) => changeDateRange(getDateRange(d, groupBy))}
                      style={{ width: '100%', marginRight: 10 }}
                      format={"DD.MM.YYYY"}
                      maxDate={dayjs()}
                      disabledDate={disabled3MonthsDate}
                    />
                  :
                    <RangePicker
                      value={dateRange}
                      onChange={(d) => changeDateRange(getDateRange(d, groupBy))}
                      style={{ width: '100%', marginRight: 10 }}
                      format={"MM.YYYY"} 
                      picker={"month"}
                      maxDate={dayjs()}
                      disabledDate={disabled12MonthsDate}
                    />
                  }
                </Col>
                <Col flex="auto">
                  <Row justify="end">
                    <Col>
                      <Space style={{ paddingRight: 5 }}>
                        <Tooltip style={{ margin: 15 }} >
                          <Button type="dashes" shape="circle" icon={<LeftOutlined />} onClick={prevStep} />
                        </Tooltip>
                      </Space>
                      <Space style={{ paddingRight: 5 }}>
                        <Tooltip style={{ margin: 15 }} >
                          <Button type="dashes" shape="circle" icon={<RightOutlined />} onClick={nextStep} />
                        </Tooltip>
                      </Space>
                      <Space style={{ paddingRight: 5 }}>
                        <Tooltip style={{ margin: 15 }} >
                          <Button type="dashes" shape="circle" icon={<SyncOutlined />} onClick={refreshChart} />
                        </Tooltip>
                      </Space>
                      <Space style={{ paddingRight: 5 }}>
                        <Tooltip title="Export" style={{ margin: 15 }} >
                          <Button type="dashes" shape="circle" icon={<DownloadOutlined />} onClick={exportToExcel} />
                        </Tooltip>
                      </Space>
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
          }>
          {emptyGraphData ?
            <Empty />
          : graphType === 'column' ?
            <Column {...config} loading={isLoading} />
          : graphType === 'line' ?
            <Line {...config} loading={isLoading} />
          :
            <Table
              rowKey={columns => columns.date}
              columns={columns}
              dataSource={graphData}
              loading={isLoading}
              size="small"
            />
          }
        </Card>
      </Spin>
    );
};

export default SingleSourceTab;