import { useState, useEffect } from 'react';
import { Form, InputNumber, Row, Col, Table, Empty, Tooltip } from 'antd';
import { useAxios } from '../../../util/AxiosUtil';
import { useKeycloak } from '@react-keycloak/web';
import { useTranslation } from "react-i18next";
import { EyeOutlined, EditOutlined, SaveOutlined, CloseOutlined } from '@ant-design/icons';

import dayjs from 'dayjs';

import BranchSensorMeasureLogAudit from './BranchSensorMeasureLogAudit';

const BranchSensorMeasureLogTable = ({ sensorData = {}, setRefresh, refresh }) => {

  const dateFormat = "YYYY-MM-DD";
  const dateTimeFromat = "DD.MM.YYYY HH:mm:ss";

  const { i18n, t } = useTranslation();
  const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);
  const { keycloak } = useKeycloak();

  const [form] = Form.useForm();
  const [limit, setLimit] = useState(5);
  const [offset, setOffset] = useState(1);
  const [pagination, setPagination] = useState({});
  const [measureData, setMeasureData] = useState([]);
  const [emptyMeasureData, setEmptyMeasureData] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [editingKey, setEditingKey] = useState('');
  const isEditing = (record) => record.measureId === editingKey;
  const [isAuditLogVisible, setIsAuditLogVisible] = useState(false);
  const [selectedMeasureId, setSelectedMeasureId] = useState();

  useEffect(() => {
    customFetch({offset: offset, limit: limit, orderBy: 'createdOn', order: 'descend'});
  }, [sensorData]);

  useEffect(() => {
    if (refresh === true)
      customFetch({offset: offset, limit: limit, orderBy: 'createdOn', order: 'descend'});
    
    setRefresh(false);
  }, [refresh]);

  const customFetch = async (searchCriteria = {}) => {
      setIsLoading(true);
      await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/branchMeasureLog/'+sensorData.sensorId, {
        params: searchCriteria
      }).then(response => {
        setMeasureData(response.data.content);
        setPagination({current: searchCriteria.offset, pageSize: searchCriteria.limit, orderBy: 'createdOn', order: 'descend', total: response.data.totalElements});
        setIsLoading(false);
      }).catch('error: ' + console.log);
  };

  const handleTableChange = (
    pagination,
    filters,
    sorter
  ) => {
    if (sorter.columnKey === undefined)
      sorter.columnKey = pagination.orderBy;

    customFetch({
      offset: pagination.current,
      limit: pagination.pageSize,
      orderBy: sorter.columnKey,
      order: sorter.order
    });
  };

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode = <InputNumber />;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `${title} je povinné pole!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const edit = (record) => {
    form.setFieldsValue({
      ...record
    });
    setEditingKey(record.measureId);
  };

  const save = async (record) => {

    try {
      const row = await form.validateFields();

      var formVals = {};
      formVals = record;
      formVals.branchSensor = sensorData;
      formVals.value = row.value;
      formVals.createdOn = dayjs().format("YYYY-MM-DD");
      formVals.modifiedBy = keycloak.idTokenParsed.preferred_username;
      formVals.modifiedOn = dayjs();

      setIsLoading(true);
      axiosClient.put(process.env.REACT_APP_ENERPO_DATA_PATH+'/branchMeasureLog', formVals)
      .then(response => {

        customFetch({
          offset: pagination.current,
          limit: pagination.pageSize
        });

        setEditingKey('');
      }).catch(error => {
        setIsLoading(false);
        console.error('There was an error!', error);
      });

    } catch (errInfo) {
      setIsLoading(false);
      console.log('Validate Failed:', errInfo);
    }
  };

  const cancel = () => {
    setEditingKey('');
  };

  const openAuditLog = (measureId) => {
    setIsAuditLogVisible(true);
    setSelectedMeasureId(measureId);
  };

  const columns = [
    {
      title: t("page.value"),
      dataIndex: 'value',
      key: 'value',
      editable: true,
      render: (text, record, index) => <div>{ parseFloat(record.value).toFixed(3) + ' ' + record.unit}</div>
    },{
      title: t("page.created_on"),
      dataIndex: 'createdOn',
      key: 'createdOn',
      render: (text, record, index) => <div>{ dayjs(record.createdOn).format(dateTimeFromat) }</div>
    },{
      title: t("page.created_by"),
      dataIndex: 'createdBy',
      key: 'createdBy'
    },{
      title: t("page.action"),
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <>
            <SaveOutlined onClick={() => save(record)} style={{ marginRight: 8 }} />
            <CloseOutlined onClick={cancel} />
          </>
        ) : (
          <>
            {editingKey === '' ?
              <EditOutlined onClick={() => edit(record)} style={{ marginRight: 8 }} />
            :
              <EditOutlined style={{ marginRight: 8, opacity: 0.2 }} />
            }
          </>
        );
      },
    },{
      title: t("page.history"),
      dataIndex: 'history',
      key: 'history',
      align: 'center',
      render: (_, record) => {
        return (
          <Tooltip title={t("page.show_history")}>
            <EyeOutlined onClick={() => openAuditLog(record.measureId)} />
          </Tooltip>
        );
      },
    }
  ]

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <>
      <Row gutter={16}>
        <Col span={24}>
          {emptyMeasureData ?
            <Empty />
          :
            <Form form={form} onValuesChange={(values) => setFormValues(values)} component={false}>
              <Table
                rowKey={measureData => measureData.measureId}
                columns={mergedColumns}
                dataSource={measureData}
                pagination={pagination}
                onChange={handleTableChange}
                loading={isLoading}
                size="small"
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
                rowClassName="editable-row"
              />
            </Form>
          }
        </Col>
      </Row>

      <BranchSensorMeasureLogAudit isModalVisible={isAuditLogVisible} setIsModalVisible={setIsAuditLogVisible} measureId={selectedMeasureId}/>
    </>
  );
};

export default BranchSensorMeasureLogTable;