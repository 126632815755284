import { useState, useEffect } from 'react';
import { Empty, DatePicker, Tooltip, Card, Button, Select, Space, Spin, Row, Col } from 'antd';
import { useAxios } from '../../../util/AxiosUtil';
import { useTranslation } from "react-i18next";
import { getStep, getDateFormat, getDispDateFormat, getMinutesPart, getDateRange, disabled3MonthsDate, disabled12MonthsDate } from '../../../util/ChartUtil';

import dayjs from 'dayjs';
import { Line, Column } from '@ant-design/plots';

import { LeftOutlined, RightOutlined, DownloadOutlined, SyncOutlined } from '@ant-design/icons';

const FweProductionChart = ({ fweId, unit, refresh, dateRange, changeDateRange, groupBy, changeGroupBy }) => {

  const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);
  const { RangePicker } = DatePicker;
  
  const { i18n, t } = useTranslation();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 700);
  const [graphData, setGraphData] = useState([]);
  const [emptyGraphData, setEmptyGraphData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [graphType, setGraphType] = useState("column");

  useEffect(() => {
    customFetch(dateRange[0], dateRange[1]);
  }, [fweId, dateRange, unit, refresh, groupBy]);

  useEffect(() => {
    function handleResize() {
        setIsMobile(window.innerWidth < 700)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, []);

  const customFetch = async (dateFrom, dateTo) => {
    
      setIsLoading(true);
      await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/chartData/'+fweId+'/FWE/production/'+groupBy+'/'+dateFrom.format(getDateFormat(groupBy))+'/'+dateTo.add(1,getStep(groupBy)).format(getDateFormat(groupBy)))
      .then(response => {
        
        setEmptyGraphData(false);
        if (response.data.length === 0)
          setEmptyGraphData(true);

        var myJson = [];
        for (var k = 0; k < response.data.length; k++) {
          if (response.data[k].value !== null) {
            myJson.push({
              value: unit === 'kWh' ? response.data[k].value : response.data[k].value / 1000,
              type: response.data[k].type,
              date: dayjs(response.data[k].date).format(getDispDateFormat(groupBy)) + getMinutesPart(groupBy)
            });
          }
        }

        setGraphData(myJson);
        setIsLoading(false);
      }).catch('error: ' + console.log);
  };

  const prevStep = () => {
    changeDateRange([dayjs(dateRange[0]).subtract(1,getStep(groupBy)), dayjs(dateRange[1]).subtract(1,getStep(groupBy))]);
  }
  
  const nextStep = () => {
    if (!dayjs(dateRange[1]).isSame(dayjs())) {
      changeDateRange([dayjs(dateRange[0]).add(1,getStep(groupBy)), dayjs(dateRange[1]).add(1,getStep(groupBy))]);
    } else {
      fetchData(dateRange[0], dateRange[1]);
    }
  }

  const refreshChart = () => {
    customFetch(dateRange[0], dateRange[1]);
  }

  const config = {
    data: graphData,
    xField: 'date',
    yField: 'value',
    height: 300,
    tooltip: {
      formatter: (x) => {
        return { 
          name: t("page.measured_value"), 
          value: (unit === 'kWh' ? parseFloat(x.value).toFixed(4) + ' kWh' : parseFloat(x.value).toFixed(4) + ' MWh')
        };
      },
    },
  };

  return (
    <Spin spinning={isLoading}>
      <Card
        size="small" loading={isLoading}
        title={
          <>
            <Row gutter={[5,10]}>
              <Col span={3} xs={24} md={3} xl={3} xxl={3}>
                <Select
                  onChange={changeGroupBy} 
                  defaultValue={{ value: groupBy }}
                  style={{ width: '100%', marginRight: 10 }}
                  options={[ { value: 'hour', label: "1 " + t("page.hour") }, { value: 'day', label: "1 " + t("page.day") }, { value: 'month', label: "1 " + t('page.month') } ]}
                />
              </Col>
              <Col span={3} xs={24} md={3} xl={3} xxl={3}>
                <Select 
                  onChange={setGraphType} 
                  defaultValue={{ value: 'column', label: t("page.chart_column") }}
                  style={{ width: '100%', marginRight: 10 }}
                  options={[ { value: 'column', label: t("page.chart_column") }, { value: 'line', label: t("page.chart_line") } ]}  
                />
              </Col>
              <Col span={6} xs={24} md={6} xl={6} xxl={6}>
                {groupBy === 'hour'?
                  <DatePicker 
                    value={dateRange[0]}
                    onChange={(d) => changeDateRange(getDateRange(d, groupBy))}
                    style={{ width: '100%', marginRight: 10 }}
                    format="DD.MM.YYYY" 
                    maxDate={dayjs()}
                  />
                : groupBy === 'day' ?
                  <RangePicker 
                    value={dateRange}
                    onChange={(d) => changeDateRange(getDateRange(d, groupBy))}
                    style={{ width: '100%', marginRight: 10 }}
                    format={"DD.MM.YYYY"}
                    maxDate={dayjs()}
                    disabledDate={disabled3MonthsDate}
                  />
                :
                  <RangePicker
                    value={dateRange}
                    onChange={(d) => changeDateRange(getDateRange(d, groupBy))}
                    style={{ width: '100%', marginRight: 10 }}
                    format={"MM.YYYY"} 
                    picker={"month"}
                    maxDate={dayjs()}
                    disabledDate={disabled12MonthsDate}
                  />
                }
              </Col>
              <Col flex="auto">
                <Row justify="end">
                  <Col>
                    <Space style={{ paddingRight: 5 }}>
                      <Tooltip style={{ margin: 15 }} >
                        <Button type="dashes" shape="circle" icon={<LeftOutlined />} onClick={prevStep} />
                      </Tooltip>
                    </Space>
                    <Space style={{ paddingRight: 5 }}>
                      <Tooltip style={{ margin: 15 }} >
                        <Button type="dashes" shape="circle" icon={<RightOutlined />} onClick={nextStep} />
                      </Tooltip>
                    </Space>
                    <Space style={{ paddingRight: 5 }}>
                      <Tooltip style={{ margin: 15 }} >
                        <Button type="dashes" shape="circle" icon={<SyncOutlined />} onClick={refreshChart} />
                      </Tooltip>
                    </Space>
                    {/*
                    <Space style={{ paddingLeft: 10 }}>
                      <Tooltip title="Export" style={{ margin: 15 }} >
                        <Button type="dashes" icon={<DownloadOutlined />} />
                      </Tooltip>
                    </Space>
                    */}
                </Col>
              </Row>
            </Col>
          </Row>
        </>
        }>
        {emptyGraphData ?
          <Empty />
        :
          graphType === 'column' ?
            <Column {...config} loading={isLoading} />
          : <Line {...config} loading={isLoading} />
        }
      </Card>
    </Spin>
  );
};

export default FweProductionChart;