import { useState, useEffect, useCallback } from 'react';
import { Space, Typography, Card, Row, Col, Select, Tabs, Tooltip } from 'antd';
import { useAxios } from '../../util/AxiosUtil.js';
import { useTranslation } from "react-i18next";
import isInRole from '../../security/IsInRole.js';
import Unauthorized from '../error/Unauthorized.js';
import dayjs from 'dayjs';

import HeatPumpTable from '../heat_pump/HeatPumpTable.js';
import HpAllProductionPieChart from './charts/HpAllProductionPieChart.js';
import HpAllProductionLineChart from './charts/HpAllProductionLineChart.js';
import HpAllAvgCopColumnChart from './charts/HpAllAvgCopColumnChart.js';
import HpAllCopLineChart from './charts/HpAllCopLineChart.js';

const HeatPumpList = () => {

  const { i18n, t } = useTranslation();
  const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 700);
  const { Title } = Typography;
  
  const [heatPumpListData, setHeatPumpListData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isHeatPumpsLoading, setIsHeatPumpsLoading] = useState(false);
  const [refreshForm, setRefreshForm] = useState(false);

  const [selectedKey, setSelectedKey] = useState();
  const [selectedUnit, setSelectedUnit] = useState('kWh');
  const [selectedHeatPump, setSelectedHeatPump] = useState([]);
  const [dateRange, setDateRange] = useState([dayjs(), dayjs()]);
  const [groupBy, setGroupBy] = useState("hour");

  const changeUnitAction =
    <Select 
      onChange={setSelectedUnit} 
      defaultValue={{ value: 'column', label: 'kWh' }}
      style={{ width: isMobile ? '100%' : 80 }} 
      options={[ { value: 'kWh', label: 'kWh' }, { value: 'MWh', label: 'MWh' } ]}
    />;

  const changeHeatPumpAction =
    <Select 
      mode="multiple"
      maxTagCount='responsive'
      allowClear
      maxTagPlaceholder={(omittedValues) => (
        <Tooltip title={omittedValues.map(({ label }) => label).join(', ')}>
          <span>{t("page.selected")+"..."}</span>
        </Tooltip>
      )}
      onChange={setSelectedHeatPump}
      suffixIcon={<span>{selectedHeatPump.length} / {heatPumpListData.length}</span>}
      placeholder={t("page.heat_pump_select")}
      style={{ width: isMobile ? '100%' : 180 }} 
      loading={isHeatPumpsLoading}
      value={selectedHeatPump}
      options={heatPumpListData}
    />;

  useEffect(() => {
    fetchCompanyHeatPumps();
  }, []);

  const fetchCompanyHeatPumps = async () => {

    setIsHeatPumpsLoading(true);
    setHeatPumpListData([]);
    await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/heatPump?limit=100')
    .then(response => {

      var myJson = [];
      for (var k = 0; k < response.data.content.length; k++) {
        myJson.push({
          value: response.data.content[k].id,
          label: response.data.content[k].name
        });
      }

      setSelectedHeatPump(myJson.map(a => a.value));
      setHeatPumpListData(myJson);
      setIsHeatPumpsLoading(false);
    }).catch('error: ' + console.log);
  };

  const changeTab = (tab) => {
    setSelectedKey(tab);
  };

  const changeDateRange = useCallback((value) => {
    setDateRange(value);
  }, []);

  const changeGroupBy = useCallback((value) => {
    setGroupBy(value);
    value === 'hour' ? setDateRange([dayjs(), dayjs()]) 
    : value === 'day' ? setDateRange([dayjs().subtract(14, 'd'), dayjs()]) 
    : setDateRange([dayjs().subtract(4, 'M'), dayjs()]);
  }, []);

  return (
    <>
      {isInRole(['enerpo_web']) ?
        <Row>
          <Col span={24} style={{ paddingTop: 10, paddingBottom: 20 }}>
            <Card bordered={true} styles={{body : {padding: 10}}} loading={isLoading}>

              <Row gutter={[10,10]}>
                <Col span={18} xs={24} xl={18} style={{display: 'flex' }}>
                  <Space style={{ paddingLeft: 2 }}>
                    <Title level={5}>{t("page.heat_pumps")}</Title>
                  </Space>
                </Col>

                {isMobile ?
                  <>
                    <Col span={24} style={{ marginBottom: 5 }}>
                      {changeHeatPumpAction}
                    </Col>
                    <Col span={24} style={{ marginBottom: 10 }}>
                      {changeUnitAction}
                    </Col>
                  </>
                : <></>}

                <Col span={24}>
                  <Tabs
                    type="card" 
                    onTabClick={() => changeTab()} 
                    activeKey={selectedKey} 
                    destroyInactiveTabPane={true}
                    tabBarExtraContent={!isMobile ? <>{changeHeatPumpAction} {changeUnitAction}</> : <></>}
                  >
                    <Tabs type="card" tab={t("page.production")} key="heatPumpAllProductionTab">
                      <Row gutter={[10,10]}>
                        <Col span={8} xs={24} xl={8}>
                          <HpAllProductionPieChart heatPumpIds={selectedHeatPump} unit={selectedUnit}/>
                        </Col>
                        <Col span={16} xs={24} xl={16}>
                          <HpAllProductionLineChart heatPumpIds={selectedHeatPump} unit={selectedUnit} dateRange={dateRange} changeDateRange={changeDateRange} groupBy={groupBy} changeGroupBy={changeGroupBy} />
                        </Col>
                      </Row>
                    </Tabs>
                    <Tabs type="card" tab={t("page.cop")} key="heatPumpAllCopTab">
                      <Row gutter={[10,10]}>
                        <Col span={8} xs={24} xl={8}>
                          <HpAllAvgCopColumnChart heatPumpIds={selectedHeatPump} />
                        </Col>
                        <Col span={16} xs={24} xl={16}>
                          <HpAllCopLineChart heatPumpIds={selectedHeatPump} unit={selectedUnit} dateRange={dateRange} changeDateRange={changeDateRange} groupBy={groupBy} changeGroupBy={changeGroupBy} />
                        </Col>
                      </Row>
                    </Tabs>
                  </Tabs>
                </Col>
              </Row>

              <Row gutter={16} style={{ marginTop: 10 }}>
                <Col span={24}>
                    <Card size="small">
                      <HeatPumpTable setRefresh={setRefreshForm} refresh={refreshForm} />
                    </Card>
                </Col>
              </Row>
            </Card>

          </Col>
        </Row>
      : <Unauthorized />}
    </>
  );
};

export default HeatPumpList;