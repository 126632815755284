import { useState, useEffect, useCallback } from 'react';
import { Card, Row, Col, Button, Descriptions } from 'antd';
import { useParams, Link } from "react-router-dom";
import { useAxios } from '../../util/AxiosUtil';

import isInRole from '../../security/IsInRole';
import { PlusCircleOutlined } from '@ant-design/icons';

import BoilerSensorTable from '../boiler_room_sensor/BoilerRoomSensorTable.js';
import BoilerSensorInsert from '../boiler_room_sensor/BoilerRoomSensorInsert.js';

const Boiler = () => {
    
    let params = useParams();

    const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);

    const [visibleCsForm, setVisibleCsForm] = useState(false);
    const [refreshCsForm, setRefreshCsForm] = useState(false);

    const [boilerData, setBoilerData] = useState([]);
    const [boilerRoomData, setBoilerRoomData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      customFetch();
    }, [params.id]);

    const customFetch = async () => {
      setIsLoading(true);
      await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/boiler/'+params.boilerRoomId+'/'+params.id)
      .then(response => {
          setBoilerData(response.data);
          setBoilerRoomData(response.data.boilerRoom);
          setIsLoading(false);
      }).catch('error: ' + console.log);
    };

    const showInsertCsForm = useCallback((value) => {
      if (value === 'refresh') {
          setVisibleCsForm(false);
          setRefreshCsForm(true);
      } else {
          setVisibleCsForm(value);
      }
    }, []);

    return (
      <>
        {isInRole(['enerpo_admin']) ?
            <></>
        : <></>}

        <Row>
            <Col span={24} style={{ paddingTop: 10 }}>
                <Card bordered={true} bodyStyle={{padding: 10}} loading={isLoading}>
                  <Descriptions title={"Zdroj: " + boilerData.name} size='small'>
                    <Descriptions.Item label="Kotolňa">
                      <Link to={process.env.REACT_APP_ENERPO_URL + "/boilerRoom/"+boilerRoomData.id}> {boilerRoomData.name}</Link>
                    </Descriptions.Item>
                  </Descriptions>
                </Card>
            </Col>
        </Row>

        <Row gutter={16} style={{ marginTop: 10, marginBottom: 0 }}>
          <Col span={24}>
              <Card size="small" title="Merače spotreby:"
                extra={ isInRole(['enerpo_admin']) ? <Button type="text" onClick={showInsertCsForm} icon={<PlusCircleOutlined />}>Pridať merač spotreby</Button> : <></>}
                loading={isLoading}
              >
                  <BoilerSensorTable boilerData={boilerData} setRefresh={setRefreshCsForm} refresh={refreshCsForm} />
                  <BoilerSensorInsert boilerData={boilerData} setVisible={showInsertCsForm} visible={visibleCsForm} />
              </Card>
          </Col>
        </Row>
      </>
    );
};

export default Boiler;