import { useState, useEffect } from 'react';
import { useAxios } from '../../util/AxiosUtil';
import { Button, Space, Input, Table } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { SearchOutlined, CloseOutlined } from '@ant-design/icons';

import moment from "moment";

const BoilerRoomSensorTable = ({ boilerRoomData, setRefresh, refresh }) => {

  const { i18n, t } = useTranslation();
  const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);

  const dateFromat = "DD.MM.YYYY HH:mm:ss";
  const [isLoading, setIsLoading] = useState(false);
  const [sensorListData, setSensorListData] = useState([]);
  const [limit, setLimit] = useState(5);
  const [pagination, setPagination] = useState({});
  const [formData, setFormData] = useState({});
  const [searchValue, setSearchValue] = useState();

  useEffect(() => {
      customFetch({offset: 1, limit: limit, orderBy: 'createdOn', order: 'descend'});
  }, []);
  
  useEffect(() => {
    if (refresh === true)
      customFetch({offset: 1, limit: limit, orderBy: 'createdOn', order: 'descend'});
    
    setRefresh(false);
  }, [refresh]);

  const customFetch = async (searchCriteria = {}) => {
    setIsLoading(true);
    await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/boilerRoomSensor?boilerRoomId='+boilerRoomData.id, {
      params: searchCriteria
    }).then(response => {
      setSensorListData(response.data.content);
      setPagination({current: searchCriteria.offset, pageSize: searchCriteria.limit, orderBy: 'createdOn', order: 'descend', total: response.data.totalElements, pageSizeOptions: ["5","10","25","50"], showSizeChanger: response.data.totalElements <= 5 ? false : true});
      setIsLoading(false);
    }).catch('error: ' + console.log);
  };

  const handleTableChange = (
      pagination,
      filters,
      sorter
    ) => {
      if (sorter.columnKey === undefined)
        sorter.columnKey = pagination.orderBy;

      customFetch({
        offset: pagination.current,
        limit: pagination.pageSize,
        orderBy: sorter.columnKey,
        order: sorter.order,
        ...formData
      });
  };

  const onSearch = (searchKey) => {
    var search = {search: searchKey+":"+searchValue};
    customFetch({
      offset: 1,
      limit: limit,
      orderBy: 'createdOn',
      ...search
    });
  };

  function getColumnSearchProps(dataIndex) {
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={t("page.search")}
            onChange={e => setSearchValue(e.target.value ? [e.target.value] : [])}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => onSearch(dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              {t("page.search")}
            </Button>
            <Button
              onClick={() => {
                confirm({ closeDropdown: true });
              }}
              icon={<CloseOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              {t("page.close")}
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    }
  }

  const columns = [
      {
        title: t("page.name"),
        dataIndex: 'sensorName',
        key: 'sensorName',
        sorter: (a, b) => a.sensorName - b.sensorName,
        sortDirections: ['ascend', 'descend', 'ascend'],
        ...getColumnSearchProps('sensorName'),
        render: (text, record, index) => 
        <div>
          <div>
            <Link to={process.env.REACT_APP_ENERPO_URL + "/boilerRoomSensor/"+record.boilerRoom.id+'/'+record.sensorId}>{record.sensorName}</Link>
          </div>
        </div>
/*
      },{
        title: t("page.type"),
        dataIndex: 'consumeType',
        key: 'consumeType',
        sorter: (a, b) => a.type - b.type,
        sortDirections: ['ascend', 'descend', 'ascend'],
        ...getColumnSearchProps('name'),
        render: (text, record, index) => t(record.consumeType.name)
*/
      },{
        title: t("page.unit"),
        dataIndex: 'unit',
        key: 'unit'
      },{
        title: t("page.datapoint"),
        dataIndex: 'datapointId',
        key: 'datapointId',
        ...getColumnSearchProps('datapointId'),
        responsive: ["xl"]
      },{
        title: t("page.created_by"),
        dataIndex: 'createdBy',
        key: 'createdBy',
        sorter: (a, b) => a.createdBy - b.createdBy,
        sortDirections: ['ascend', 'descend', 'ascend'],
        responsive: ["xl"]
      },{
        title: t("page.created_on"),
        dataIndex: 'createdOn',
        key: 'createdOn',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.createdOn - b.createdOn,
        sortDirections: ['ascend', 'descend', 'ascend'],
        render: (text, record, index) => record.createdOn ? moment(record.createdOn).format(dateFromat) : null,
        responsive: ["xl"]
      },{
        title: t("page.modified_by"),
        dataIndex: 'modifiedBy',
        key: 'modifiedBy',
        sorter: (a, b) => a.modifiedBy - b.modifiedBy,
        sortDirections: ['ascend', 'descend', 'ascend'],
        responsive: ["xl"]
      },{
        title: t("page.modified_on"),
        dataIndex: 'modifiedOn',
        key: 'modifiedOn',
        sorter: (a, b) => a.modifiedOn - b.modifiedOn,
        sortDirections: ['ascend', 'descend', 'ascend'],
        render: (text, record, index) => record.modifiedOn ? moment(record.modifiedOn).format(dateFromat) : null,
        responsive: ["xl"]
      }
    ];

    return (
      <Table
        rowKey={sensorListData => sensorListData.boilerSensorId}
        columns={columns}
        dataSource={sensorListData}
        pagination={pagination}
        onChange={handleTableChange}
        loading={isLoading}
        size="small"
      />
    );
};

export default BoilerRoomSensorTable;