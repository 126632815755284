import dayjs from 'dayjs';

export function getStep(groupBy) {
    
  switch (groupBy) {
    case 'hour':
      return 'd';
    case 'day':
      return 'd';
    case 'month':
      return 'M';
  }
}

export const getChartUnit = (unit, chartUnit) => {
  if (chartUnit.toLowerCase() === 'kwh') {
    return unit === 'kWh' ? 'kWh' : 'MWh';
  } else {
    return chartUnit;
  }
}

export const getChartUnitByType = (t, type, unit) => {
  
  switch (type) {
    case t("page.energy"):
      return unit === 'kWh' ? 'kWh' : 'MWh';
    case t("page.power"):
      return unit === 'kWh' ? 'kW' : 'MW';
    default:
      return '';
  }
}

export function getDateFormat(groupBy) {

  const dateFormat = "YYYY-MM-DD";
  const dateFormatMonth = "YYYY-MM";
  
  switch (groupBy) {
    case 'hour':
      return dateFormat;
    case 'day':
      return dateFormat;
    case 'month':
      return dateFormatMonth;
  }
}

export function getDispDateFormat(groupBy) {

  switch (groupBy) {
    case 'hour':
      return "DD.MM.YYYY HH";
    case 'day':
      return "DD.MM.YYYY";
    case 'month':
      return "MM/YYYY";
  }
}

export function getMinutesPart(groupBy) {
  return groupBy === 'hour' ? ':00' : '';
}

export function getDateRange(date, groupBy) {

  if (date !== null && groupBy === 'hour') {
    var changedDateFrom = dayjs(date);
    var changedDateTo = dayjs(date);

    return [dayjs(date), dayjs(date)];
  } else if (date !== null && (date[0] !== null && date[1] !== null)) {
    var changedDateFrom = dayjs(date[0]);
    var changedDateTo = dayjs(date[1]);

    if (date[0] === null)
      changedDateFrom = dayjs();

    if (date[1] === null)
      changedDateTo = dayjs();

    return [changedDateFrom, changedDateTo];
  }
}

export const disabled3MonthsDate = (current, { from }) => {
  if (from) {
    const curMonth = current.year() * 12 + current.month();
    const fromMonth = from.year() * 12 + from.month();
    return Math.abs(fromMonth - curMonth) >= 3;
  }
  return false;
};

export const disabled12MonthsDate = (current, { from }) => {
  if (from) {
    const curMonth = current.year() * 12 + current.month();
    const fromMonth = from.year() * 12 + from.month();
    return Math.abs(fromMonth - curMonth) >= 12;
  }
  return false;
};