import { useEffect, useState } from 'react';
import { Modal, Form, Input, Alert, Select, Divider } from 'antd';
import { useTranslation } from "react-i18next";
import { useAxios } from '../../util/AxiosUtil';
import { useKeycloak } from '@react-keycloak/web';
import moment from 'moment';

const BranchSensorInsert = ({ branchData = {}, setVisible, visible }) => {

    const { i18n, t } = useTranslation();
    const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);
    const { keycloak } = useKeycloak();
    
    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();
    const [formErrorText, setFormErrorText] = useState();
    const [datapoints, setDatapoints] = useState([]);

    useEffect(() => {
      fetchDatapoints();
    }, [branchData.id]);

    const fetchDatapoints = async () => {
      
      setIsLoading(true);
      await axiosClient.get(process.env.REACT_APP_DISPATCH_DATA_PATH+'/datapoint?projectId='+branchData.boilerRoom.projectId+'&schemaId='+branchData.boilerRoom.schemaId)
      .then(response => {
        setDatapoints(response.data);
        setIsLoading(false);
      }).catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
    };

    const onSubmit = (formVals) => {

      formVals.branch = branchData;
      formVals.consumeType = {id: formVals.consumeTypeId};
      formVals.createdBy = keycloak.idTokenParsed.preferred_username;
      formVals.createdOn = moment();
      formVals.modifiedBy = keycloak.idTokenParsed.preferred_username;
      formVals.modifiedOn = moment();
      
      setIsLoading(true);
      axiosClient.post(process.env.REACT_APP_ENERPO_DATA_PATH+'/branchSensor', formVals)
      .then(response => {
        form.resetFields();
        
        setIsLoading(false);
        setVisible('refresh');
      }).catch(error => {
        setIsLoading(false);
        console.error('There was an error!', error.response.data);
        setFormErrorText(error.response.data.errors);
      });
    };

    const onClose = () => {
      setVisible(false);
    };

    const layout = {
      labelCol: { span: 6, },
      wrapperCol: { span: 14,},
    };

    return (
      <Modal 
        title={t("page.sensor_consume_create")+":"}
        visible={visible} 
        confirmLoading={isLoading}
        onOk={() => {
          form
            .validateFields()
            .then(values => {
              onSubmit(values);
            })
            .catch(info => {
              console.log('Validate Failed:', info);
            });
        }}
        onCancel={onClose}
      >
        {formErrorText ? (
          <Alert message={formErrorText} type="error" />
        ) : null}
          <Divider />
          <Form {...layout} form={form} onFinish={onSubmit}>
            <Form.Item name="sensorName" label={t("page.name")} rules={[{required: true, message: t("page.name.mandatory")}]}>
              <Input />
            </Form.Item>
            <Form.Item name="consumeTypeId" label={t("page.type")} rules={[{required: true, message: t("page.type.mandatory")}]}>
              <Select>
                <Option value="1">{t("Spotreba plynu")}</Option>
                <Option value="2">{t("Spotreba elektriny")}</Option>
                <Option value="3">{t("Spotreba tepla")}</Option>
                <Option value="4">{t("Spotreba vody")}</Option>
              </Select>
            </Form.Item>
            <Form.Item name="unit" label={t("page.unit")}>
              <Select>
                <Option value='MWh'>MWh</Option>
                <Option value='kWh'>kWh</Option>
                <Option value='m3'>m3</Option>
              </Select>
            </Form.Item>
            <br/>
            <Form.Item name="dispatching" label={t("page.dispatching")}>
              <Select>
                <Option value=""></Option>
                <Option value='Merbon Scada TN'>Merbon Scada TN</Option>
              </Select>
            </Form.Item>
            <Form.Item name="projectId" label={t("page.project_id")}>
              <Input />
            </Form.Item>
            <Form.Item name="datapointId" label="Datapoint ID">
              <Input />
            </Form.Item>
          </Form>
      </Modal>
    );
};

export default BranchSensorInsert;