import { useState, useCallback } from 'react';
import { Button, Card, Spin } from 'antd';
import { useTranslation } from "react-i18next";

import isInRole from '../../../security/IsInRole';
import { PlusCircleOutlined, TableOutlined, BarChartOutlined } from '@ant-design/icons';

import BranchSensorMeasureLogChart from './BranchSensorMeasureLogChart';
import BranchSensorMeasureLogTable from './BranchSensorMeasureLogTable';
import BranchSensorMeasureInsert from './BranchSensorMeasureInsert';

const BranchSensorMeasureLogCard = ({ sensorData, loading }) => {

  const { i18n, t } = useTranslation();

  const [visibleForm, setVisibleForm] = useState(false);
  const [refreshForm, setRefreshForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tableStyle, setTableStyle] = useState(false);

  const showInsertForm = useCallback((value) => {
    if (value === 'refresh') {
        setVisibleForm(false);
        setRefreshForm(true);
    } else {
        setVisibleForm(value);
    }
  }, []);

  return (
    <Spin spinning={isLoading}>
      <Card size="small" title={t("page.history_measure")+":"}
        extra={
          isInRole(['enerpo_admin']) ? 
            <>
              <Button type="text" onClick={showInsertForm} icon={<PlusCircleOutlined />}>{t("page.measure_add")}</Button> 
              {tableStyle ? 
                <BarChartOutlined onClick={() => setTableStyle(false)} />
              : 
                <TableOutlined onClick={() => setTableStyle(true)} /> 
              }
            </>
          : <></>
        }
        bordered={true} loading={loading}>
        {tableStyle ?
          <BranchSensorMeasureLogTable sensorData={sensorData} setRefresh={setRefreshForm} refresh={refreshForm} />
        :
          <BranchSensorMeasureLogChart sensorId={sensorData.sensorId} setRefresh={setRefreshForm} refresh={refreshForm} />
        }
        <BranchSensorMeasureInsert sensorData={sensorData} setVisible={showInsertForm} visible={visibleForm} />
      </Card>
    </Spin>
  );
};

export default BranchSensorMeasureLogCard;