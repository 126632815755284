import { useEffect, useState } from 'react';
import { Modal, Form, InputNumber, Input, Alert, DatePicker, Divider } from 'antd';
import { useAxios } from '../../../../../util/AxiosUtil';
import { useKeycloak } from '@react-keycloak/web';
import dayjs from 'dayjs';
import { useTranslation } from "react-i18next";

const GassInsert = ({ setVisible, visible }) => {

    const { i18n, t } = useTranslation();
    const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);
    const { keycloak } = useKeycloak();

    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();
    const [formErrorText, setFormErrorText] = useState();

    const onSubmit = (formVals) => {

      formVals.month = dayjs(formVals.month).startOf('month').add(1,'d');
      formVals.createdOn = dayjs();
      formVals.createdBy = keycloak.idTokenParsed.preferred_username;
      formVals.modifiedBy = keycloak.idTokenParsed.preferred_username;
      formVals.modifiedOn = dayjs();

      setIsLoading(true);
      axiosClient.post(process.env.REACT_APP_ENERPO_DATA_PATH+'/okte/gass', formVals)
      .then(response => {

        form.resetFields();
        setIsLoading(false);
        setVisible('refresh');
      }).catch(error => {
        setIsLoading(false);
        console.error('There was an error!', error);
        setFormErrorText(error.response.data.errors);
      });
    };

    const onClose = () => {
      setVisible(false);
    };

    const layout = {
      labelCol: { span: 6, },
      wrapperCol: { span: 12,},
    };

    const priceSuffix = (
      <Form.Item name="unit" noStyle>€/MWh</Form.Item>
    );

    const disabledDate = (current) => {
      return current && current > dayjs().add(6, 'M');
    };
    
    return (
      <Modal 
        title={t("page.price_add")+":"}
        visible={visible} 
        confirmLoading={isLoading}
        onOk={() => {
          form
            .validateFields()
            .then(values => {
              onSubmit(values);
            })
            .catch(info => {
              console.log('Validate Failed:', info);
            });
        }}
        onCancel={onClose}
      >
        {formErrorText ? (
          <Alert message={formErrorText} type="error" />
        ) : null}
          <Divider />
          <Form {...layout} form={form} onFinish={onSubmit}>
            <Form.Item name="month" label={t("page.date")} rules={[{required: true, message: t("page.date.mandatory")}]}>
              <DatePicker format="MM.YYYY" picker="month" style={{ width: '100%' }} disabledDate={disabledDate} />
            </Form.Item>
            <Form.Item name="price" label={t("page.value")} rules={[{required: true, message: t("page.value.mandatory")}]}>
              <InputNumber addonAfter={priceSuffix} style={{ width: '100%' }} />
            </Form.Item>
          </Form>
      </Modal>
    );
};

export default GassInsert;