import { useState, useEffect } from 'react';
import { Spin, Empty } from 'antd';
import { useAxios } from '../../util/AxiosUtil';
import { Link } from 'react-router-dom';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';

const BoilerRoomMap = ({ companyData = {}, height, zoom, refreshMap = false }) => {

    const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);
    const [mapData, setMapData] = useState([]);
    const [centralMap, setCentralMap] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [boilerRoomListData, setBoilerRoomListData] = useState([]);

    useEffect(() => {
        const L = require("leaflet");

        delete L.Icon.Default.prototype._getIconUrl;

        L.Icon.Default.mergeOptions({
            iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
            iconUrl: require("leaflet/dist/images/marker-icon.png"),
            shadowUrl: require("leaflet/dist/images/marker-shadow.png")
        });
    }, []);

    useEffect(() => {
        if (companyData.id) {
            fetchByCompany({offset: 1, limit: 100, orderBy: 'createdOn', order: 'descend'});
        } else {
            customFetch({offset: 1, limit: 100, orderBy: 'createdOn', order: 'descend'});
        }
    }, [companyData.id, refreshMap]);

    const customFetch = async (searchCriteria = {}) => {
    
        setIsLoading(true);
        await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/boilerRoom', {
            params: searchCriteria
        }).then(response => {
            if (Object.keys(response.data.content).length > 0)
                setCentralMap([response.data.content[0].lat, response.data.content[0].lng]);
            
            setBoilerRoomListData(response.data.content);
            setIsLoading(false);
        }).catch('error: ' + console.log);
    };

    const fetchByCompany = async (searchCriteria = {}) => {
    
        setIsLoading(true);
        await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/boilerRoom?search=isVirtual:false,companyId:'+companyData.id, {
            params: searchCriteria
        }).then(response => {
            if (Object.keys(response.data.content).length > 0)
                setCentralMap([response.data.content[0].lat, response.data.content[0].lng]);

            setBoilerRoomListData(response.data.content);
            setIsLoading(false);
        }).catch('error: ' + console.log);
    };

    function ChangeView({ center }) {
        const map = useMap();
        map.setView(center);
        return null;
    }

    return (
        <Spin spinning={isLoading}>
            {centralMap ?
                <MapContainer center={centralMap} zoom={zoom} scrollWheelZoom={false} style={{ height: height }}>
                    <ChangeView center={centralMap} />
                    <TileLayer attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" 
                    />
                        {boilerRoomListData.map((option) => (
                            <Marker position={[option.lat, option.lng]}>
                                <Popup>
                                    <Link to={process.env.REACT_APP_ENERPO_URL + "/boilerRoom/" + option.id}>{option.name}</Link>
                                </Popup>
                            </Marker>
                        ))}
                </MapContainer>
            : 
                <Empty />
            }
        </Spin>
    );

};

export default BoilerRoomMap;