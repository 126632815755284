import { useState, useCallback, useContext } from "react";
import { Layout, Row, Col, Select, Space } from 'antd';
import { Drawer, Button } from "antd";
import { MenuOutlined, PoweroffOutlined } from "@ant-design/icons";
import { EnerpoMenu } from './EnerpoMenu';
import { Link } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { useTranslation } from "react-i18next";

import { LocaleContext } from '../context/LocaleContext';
import logo from './../../static/img/logo.jpg';

const { Header } = Layout;

export const EnerpoHeader = ({ firstname, lastname }) => {
  
    const { i18n, t } = useTranslation();

    const { locale, setLocale } = useContext(LocaleContext);
    const [ selectedLocale, setSelectedLocale ] = useState('🇬🇧'+' '+t("locale.eng"));

    const { keycloak } = useKeycloak();

    const [visible, setVisible] = useState(false);

    const [logoffBgColour, setLogoffBgColour] = useState("white")
    const logoffStyles={
        fontSize: '18px',
        color:`${logoffBgColour}`
    }

    const closeDrawer = useCallback(() => {
        setVisible(false);
    }, []);

    const logout = async () => {
        keycloak.logout();
    };

    const changeLocale = (l) => {
        i18n.changeLanguage(l);
        setLocale(l);
        setSelectedLocale(l);
    };

    const options = [
        { label: '🇬🇧'+' '+t("locale.eng"), value: 'en', emoji: '🇬🇧', desc: t("locale.eng") },
        { label: '🇸🇰'+' '+t("locale.sk"), value: 'sk', emoji: '🇸🇰', desc: t("locale.sk") },
    ]

    return (
        <Header style={{ position: 'fixed', zIndex: 10000, width: '100%' }}>
            <Row>
                <Col flex="none" xs={3} sm={4} md={6} lg={0}>
                    <nav className="navbar">
                        <Button
                            className="menu"
                            type="primary"
                            icon={<MenuOutlined />}
                            onClick={() => setVisible(true)}
                            style={{ marginRight: '15px' }}
                        />
                        <Drawer
                            title="Menu"
                            placement="left"
                            onClick={() => setVisible(false)}
                            onClose={() => setVisible(false)}
                            open={visible}
                        > 
                            <EnerpoMenu closeMenu={closeDrawer} />
                        </Drawer>
                    </nav>
                </Col>

                <Col xs={0} sm={24} md={24} lg={24} xl={24} xxl={24} style={{ paddingLeft: '0px', textAlign: 'left' }} flex="auto">
                    <Link to={"/"}>
                        <h2 style={{color : 'white', fontSize: '16px' }}>
                            <img src={logo} style={{ height : '50px', paddingRight: '10px', width: 'auto', paddingTop: '0px'}} alt='ENERPO'/>
                            {t("page.portal.name")}
                        </h2>
                    </Link>
                </Col>

                <Col xs={0} sm={24} md={24} lg={24} xl={24} xxl={24} flex="auto">
                    <h2 style={{color : 'white', textAlign: 'right', fontSize: '16px' }}>
                        {firstname} {lastname}
                    </h2>
                </Col>
                <Col xs={24} sm={5} md={4} lg={4} xl={3} style={{ paddingLeft: '25px', textAlign: 'right' }} flex="auto">
                    <Select
                        style={{ textAlign: 'left', width: '100%', zIndex: 1000000 }}
                        defaultValue={['🇬🇧'+' '+t("locale.eng")]}
                        value={selectedLocale}
                        onChange={changeLocale}
                        options={options}
                        optionRender={(option) => (
                            <Space>
                                <span role="img" aria-label={option.data.label}>
                                    {option.data.emoji}
                                </span>
                                {option.data.desc}
                            </Space>
                        )}
                    />
                </Col>
                <Col xs={24} flex="40px" style={{ paddingLeft: '0px', textAlign: 'right' }}>
                    <PoweroffOutlined 
                            style={logoffStyles}
                            onMouseEnter={() => setLogoffBgColour("#999999")}
                            onMouseLeave={() => setLogoffBgColour("white")}
                            onClick={logout} />
                </Col>
            </Row>
        </Header>
    );
  
}