import { useState, useEffect } from 'react';
import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import isInRole from '../security/IsInRole';

import { CarBattery, SolarPanel, Building, Factory, Fan } from "@phosphor-icons/react";

export const EnerpoMenu = ({ closeMenu }) => {

    const { i18n, t } = useTranslation();

    const location = useLocation();
    const [selectedKey, setSelectedKey] = useState("dashboard");
    
    useEffect(() => {
        if (location.pathname !== '/' && location.pathname !== process.env.REACT_APP_ENERPO_URL) {
            let pathname = location.pathname.split("/");
            setSelectedKey(pathname[2]);
        }
    }, [location]);

    const menuItems = [
        /*{
            key: 'dashboard',
            icon: <AreaChartOutlined />,
            label: <Link to={process.env.REACT_APP_ENERPO_URL + "/dashboard"}>Administračný panel</Link>
        },*/
        {
            key: 'company',
            icon: <Building size={20}/>,
            label: <Link to={process.env.REACT_APP_ENERPO_URL + "/company"}>{t("page.menu.companies")}</Link>
        },{
            key: 'boilerRoom',
            icon: <Factory size={20} />,
            label: <Link to={process.env.REACT_APP_ENERPO_URL + "/boilerRoom"}>{t("page.menu.boiler_rooms")}</Link>
        },{
            key: 'heatPump',
            icon: <Fan size={20} />,
            label: <Link to={process.env.REACT_APP_ENERPO_URL + "/heatPump"}>{t("page.heat_pumps")}</Link>
        },{
            key: 'fwe',
            icon: <SolarPanel size={20} />,
            label: <Link to={process.env.REACT_APP_ENERPO_URL + "/fwe"}>{t("page.fves")}</Link>
        },{
            key: 'battery',
            icon: <CarBattery size={20} />,
            label: <Link to={process.env.REACT_APP_ENERPO_URL + "/battery"}>{t("page.batteries")}</Link>
        }
    ]

    return (
        <Menu defaultSelectedKeys={['dashboard']} 
            selectedKeys={selectedKey} 
            style={{ zIndex: 10000 }} 
            onClick={closeMenu} 
            items={isInRole(['enerpo_web']) ? menuItems : null}
        />
    );
  
}