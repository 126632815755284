import { useState, useCallback } from 'react';
import { Button, Card, Spin } from 'antd';
import { useTranslation } from "react-i18next";

import isInRole from '../../security/IsInRole';
import { PlusCircleOutlined, TableOutlined, BarChartOutlined } from '@ant-design/icons';

import ThermoSensorMeasureLogChart from './ThermoSensorMeasureLogChart';
import ThermoSensorMeasureLogTable from './ThermoSensorMeasureLogTable';
import ThermoSensorMeasureInsert from './ThermoSensorMeasureInsert';

const ThermoSensorMeasureLogCard = ({ sensorData, loading }) => {

  const { i18n, t } = useTranslation();
  const [visibleForm, setVisibleForm] = useState(false);
  const [refreshForm, setRefreshForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tableStyle, setTableStyle] = useState(false);

  const showInsertForm = useCallback((value) => {
    if (value === 'refresh') {
        setVisibleForm(false);
        setRefreshForm(true);
    } else {
        setVisibleForm(value);
    }
  }, []);

  return (
    <Spin spinning={isLoading}>
      <Card size="small" title={t("page.history_measure")+":"}
        extra={
          isInRole(['enerpo_admin']) ? 
            <>
              <Button type="text" onClick={showInsertForm} icon={<PlusCircleOutlined style={{ marginRight: 5 }} />}>{t("page.measure_add")}</Button>
              {tableStyle ? 
                <BarChartOutlined onClick={() => setTableStyle(false)} />
              : 
                <TableOutlined onClick={() => setTableStyle(true)} /> 
              }
            </>
          : <></>
        }
        bordered={true} loading={loading}>
        {tableStyle ?
          <ThermoSensorMeasureLogTable sensorData={sensorData} setRefresh={setRefreshForm} refresh={refreshForm} />
        :
          <ThermoSensorMeasureLogChart thermoSensorId={sensorData.thermoSensorId} setRefresh={setRefreshForm} refresh={refreshForm} />
        }
        <ThermoSensorMeasureInsert sensorData={sensorData} setVisible={showInsertForm} visible={visibleForm} />
      </Card>
    </Spin>
  );
};

export default ThermoSensorMeasureLogCard;