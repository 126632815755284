import Keycloak from 'keycloak-js'

const keycloakConfig = {
    realm: "digma",
    url: "https://www.digma.sk/auth",
    clientId: "enerpo-web"
}

const keycloak = new Keycloak(keycloakConfig);

export default keycloak