import { useState, useEffect, useCallback } from 'react';
import { Space, Typography, Card, Row, Col, Select, Tabs, Tooltip } from 'antd';
import { useAxios } from '../../util/AxiosUtil.js';
import { useTranslation } from "react-i18next";
import isInRole from '../../security/IsInRole.js';
import Unauthorized from '../error/Unauthorized.js';
import dayjs from 'dayjs';

import BatteryTable from '../battery/BatteryTable.js';
import BatteryAllChargingPieChart from './charts/BatteryAllChargingPieChart.js';
import BatteryAllChargingLineChart from './charts/BatteryAllChargingLineChart.js';
import BatteryAllCapacityPieChart from './charts/BatteryAllCapacityPieChart.js';
import BatteryAllCapacityLineChart from './charts/BatteryAllCapacityLineChart.js';

const BatteryList = () => {

  const { i18n, t } = useTranslation();
  const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 700);
  const { Title } = Typography;
  
  const [batteryListData, setBatteryListData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isBatteriesLoading, setIsBatteriesLoading] = useState(false);
  const [refreshForm, setRefreshForm] = useState(false);

  const [selectedKey, setSelectedKey] = useState();
  const [selectedUnit, setSelectedUnit] = useState('kWh');
  const [selectedBattery, setSelectedBattery] = useState([]);
  const [dateRange, setDateRange] = useState([dayjs(), dayjs()]);
  const [groupBy, setGroupBy] = useState("hour");

  const changeUnitAction =
    <Select 
      onChange={setSelectedUnit} 
      defaultValue={{ value: 'column', label: 'kWh' }}
      style={{ width: isMobile ? '100%' : 80 }} 
      options={[ { value: 'kWh', label: 'kWh' }, { value: 'MWh', label: 'MWh' } ]}
    />;

  const changeBatteryAction =
    <Select 
      mode="multiple"
      maxTagCount='responsive'
      allowClear
      maxTagPlaceholder={(omittedValues) => (
        <Tooltip title={omittedValues.map(({ label }) => label).join(', ')}>
          <span>{t("page.selected")+"..."}</span>
        </Tooltip>
      )}
      onChange={setSelectedBattery}
      suffixIcon={<span>{selectedBattery.length} / {batteryListData.length}</span>}
      placeholder={t("page.battery_change")}
      style={{ width: isMobile ? '100%' : 180 }} 
      loading={isBatteriesLoading}
      value={selectedBattery}
      options={batteryListData}
  />;

  useEffect(() => {
    fetchCompanyBatteries();
  }, []);

  const fetchCompanyBatteries = async () => {

    setIsBatteriesLoading(true);
    setBatteryListData([]);
    await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/battery?limit=20')
    .then(response => {

      var myJson = [];
      for (var k = 0; k < response.data.content.length; k++) {
        myJson.push({
          value: response.data.content[k].id,
          label: response.data.content[k].name
        });
      }

      setSelectedBattery(myJson.map(a => a.value));
      setBatteryListData(myJson);
      setIsBatteriesLoading(false);
    }).catch('error: ' + console.log);
  };

  const changeTab = (tab) => {
    setSelectedKey(tab);
  };

  const changeDateRange = useCallback((value) => {
    setDateRange(value);
  }, []);

  const changeGroupBy = useCallback((value) => {
    setGroupBy(value);
    value === 'hour' ? setDateRange([dayjs(), dayjs()]) 
    : value === 'day' ? setDateRange([dayjs().subtract(14, 'd'), dayjs()]) 
    : setDateRange([dayjs().subtract(4, 'M'), dayjs()]);
  }, []);
  
  return (
    <>
      {isInRole(['enerpo_web']) ?
        <Row>
          <Col span={24} style={{ paddingTop: 10, paddingBottom: 20 }}>
            <Card bordered={true} styles={{body : {padding: 10}}} loading={isLoading}>

              <Row gutter={[10,10]}>
                <Col span={18} xs={24} xl={18} style={{display: 'flex' }}>
                  <Space style={{ paddingLeft: 2 }}>
                    <Title level={5}>{t("page.batteries")}</Title>
                  </Space>
                </Col>

                {isMobile ?
                  <>
                    <Col span={24} style={{ marginBottom: 5 }}>
                      {changeBatteryAction}
                    </Col>
                    <Col span={24} style={{ marginBottom: 10 }}>
                      {changeUnitAction}
                    </Col>
                  </>
                : <></>}

                <Col span={24}>
                  <Tabs
                    type="card" 
                    onTabClick={() => changeTab()} 
                    activeKey={selectedKey} 
                    destroyInactiveTabPane={true}
                    tabBarExtraContent={!isMobile ? <>{changeBatteryAction} {changeUnitAction}</> : <></>}
                  >
                    <Tabs type="card" tab={t("page.charging")} key="batteryAllChargingTab">
                      <Row gutter={[10,10]}>
                        <Col span={8} xs={24} xl={8}>
                          <BatteryAllChargingPieChart batteryIds={selectedBattery} unit={selectedUnit}/>
                        </Col>
                        <Col span={16} xs={24} xl={16}>
                          <BatteryAllChargingLineChart batteryIds={selectedBattery} unit={selectedUnit} dateRange={dateRange} changeDateRange={changeDateRange} groupBy={groupBy} changeGroupBy={changeGroupBy} />
                        </Col>
                      </Row>
                    </Tabs>
                    <Tabs type="card" tab={t("page.capacity")} key="batteryAllCapacityTab">
                      <Row gutter={[10,10]}>
                        <Col span={8} xs={24} xl={8}>
                          <BatteryAllCapacityPieChart batteryIds={selectedBattery} unit={selectedUnit}/>
                        </Col>
                        <Col span={16} xs={24} xl={16}>
                          <BatteryAllCapacityLineChart batteryIds={selectedBattery} unit={selectedUnit} dateRange={dateRange} changeDateRange={changeDateRange} groupBy={groupBy} changeGroupBy={changeGroupBy} />
                        </Col>
                      </Row>
                    </Tabs>
                  </Tabs>
                </Col>
              </Row>

              <Row gutter={16} style={{ marginTop: 10 }}>
                <Col span={24}>
                    <Card size="small">
                      <BatteryTable setRefresh={setRefreshForm} refresh={refreshForm} />
                    </Card>
                </Col>
              </Row>
            </Card>

          </Col>
        </Row>
      : <Unauthorized />}
    </>
  );
};

export default BatteryList;