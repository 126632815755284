import { useState, useCallback } from 'react';
import { Button, Card, Spin } from 'antd';
import { useTranslation } from "react-i18next";

import isInRole from '../../../security/IsInRole';
import { TableOutlined, BarChartOutlined } from '@ant-design/icons';

import BranchSensorConsumeLogChart from './BranchSensorConsumeLogChart';
import BranchSensorConsumeLogTable from './BranchSensorConsumeLogTable';

const BranchSensorConsumeLogCard = ({ sensorData, loading }) => {

  const { i18n, t } = useTranslation();
  const [refreshForm, setRefreshForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tableStyle, setTableStyle] = useState(false);

  return (
    <Spin spinning={isLoading}>
      <Card size="small" title={t("page.history_consume")+":"}
        extra={
          isInRole(['enerpo_admin']) ? 
            <>
              {tableStyle ? 
                <BarChartOutlined onClick={() => setTableStyle(false)} />
              : 
                <TableOutlined onClick={() => setTableStyle(true)} /> 
              }
            </>
          : <></>
        }
        bordered={true} loading={loading}>
        {tableStyle ?
          <BranchSensorConsumeLogTable sensorData={sensorData} setRefresh={setRefreshForm} refresh={refreshForm} />
        :
          <BranchSensorConsumeLogChart sensorId={sensorData.sensorId} setRefresh={setRefreshForm} refresh={refreshForm} />
        }
      </Card>
    </Spin>
  );
};

export default BranchSensorConsumeLogCard;