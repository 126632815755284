import { useState, useEffect, useCallback } from 'react';
import { Tabs, Card, Row, Col, Typography, Select, Space, Breadcrumb } from 'antd';
import { useParams, useNavigate, Link } from "react-router-dom";
import { useAxios } from '../../util/AxiosUtil';
import { useKeycloak } from '@react-keycloak/web';
import { useTranslation } from "react-i18next";

import isInRole from '../../security/IsInRole';
import { getWeather } from '../../util/WeatherUtil';

import BatteryChargeTodayCard from './chartCards/BatteryChargeTodayCard';
import BatteryChargeTotalCard from './chartCards/BatteryChargeTotalCard';
import BatteryDischargeTodayCard from './chartCards/BatteryDischargeTodayCard';
import BatteryDischargeTotalCard from './chartCards/BatteryDischargeTotalCard';

import BatteryPowerChart from './charts/BatteryPowerChart';
import BatteryChargeStateChart from './charts/BatteryChargeStateChart';
import BatteryEnergyStateChart from './charts/BatteryEnergyStateChart';
import BatteryHeatStateChart from './charts/BatteryHeatStateChart';

import BatteryChargingChart from './charts/BatteryChargingChart';
import BatteryCapacityChart from './charts/BatteryCapacityChart';

import ElectricityTab from '../boiler_room/tabs/prices/sub_tabs/ElectricityTab';

import dayjs from 'dayjs';

import { HomeOutlined } from '@ant-design/icons';

const Battery = () => {
    
    let params = useParams();
    const { TabPane } = Tabs;
    const { Title } = Typography;
    const { Paragraph } = Typography;

    const { i18n, t } = useTranslation();
    const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);
    const { keycloak } = useKeycloak();
    const navigate = useNavigate();

    const [isMobile, setIsMobile] = useState(window.innerWidth < 700);

    const [batteryData, setBatteryData] = useState([]);
    const [boilerRoomData, setBoilerRoomData] = useState([]);
    const [batteryListData, setBatteryListData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isBatteriesLoading, setIsBatteriesLoading] = useState(false);
    const [dateRange, setDateRange] = useState([dayjs(), dayjs()]);

    const [groupBy, setGroupBy] = useState("hour");
    const [selectedKey, setSelectedKey] = useState();
    const [selectedUnit, setSelectedUnit] = useState('kWh');
    
    const changeUnitAction =
      <Select 
        onChange={setSelectedUnit} 
        defaultValue={{ value: 'column', label: 'kWh' }}
        style={{ width: isMobile ? '100%' : 80 }} 
        options={[ { value: 'kWh', label: 'kWh' }, { value: 'MWh', label: 'MWh' } ]}
      />;

    useEffect(() => {
      customFetch();
    }, [params.id]);

    useEffect(() => {
      function handleResize() {
          setIsMobile(window.innerWidth < 700)
      }

      window.addEventListener('resize', handleResize)
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }, []);

    const customFetch = async () => {
      setIsLoading(true);
      await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/battery/'+params.id)
      .then(response => {
          setBatteryData(response.data);
          setBoilerRoomData(response.data.boilerRoom);
          setIsLoading(false);
      }).catch('error: ' + console.log);
    };

    const fetchCompanyBatteries = async (companyId) => {

      setIsBatteriesLoading(true);
      setBatteryListData([]);
      await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/battery?limit=20&search=boilerRoomcompanyId:'+companyId)
      .then(response => {

        var myJson = [];
        for (var k = 0; k < response.data.content.length; k++) {
          if (response.data.content[k].id !== batteryData.id) {
            myJson.push({
              value: response.data.content[k].id,
              label: response.data.content[k].name
            });
          }
        }

        setBatteryListData(myJson);
        setIsBatteriesLoading(false);
      }).catch('error: ' + console.log);
    };

    const changeBatteries = (id) => {
      navigate(process.env.REACT_APP_ENERPO_URL + '/battery/' + id);
    };

    const changeTab = (tab) => {
      setSelectedKey(tab);
    };

    const changeDateRange = useCallback((value) => {
      if (value) setDateRange(value);
    }, []);

    const changeGroupBy = useCallback((value) => {
      setGroupBy(value);
      value === 'hour' ? setDateRange([dayjs(), dayjs()]) 
      : value === 'day' ? setDateRange([dayjs().subtract(14, 'd'), dayjs()]) 
      : setDateRange([dayjs().subtract(4, 'M'), dayjs()]);
    }, []);

    return (
      <>
        {isInRole(['enerpo_admin']) ?
            <></>
        : <></>}

        <Breadcrumb
          items={[
            { title: <Link to={process.env.REACT_APP_ENERPO_URL + "/company/"+boilerRoomData.companyId}><HomeOutlined /> {t("page.company")}</Link>, },
            { title: <Link to={process.env.REACT_APP_ENERPO_URL + "/boilerRoom/"+boilerRoomData.id}>{t("page.boiler_room")}</Link>, },
            { title: t("page.battery") },
          ]}
        />

        <Row>
            <Col span={24} style={{ paddingTop: 10, paddingBottom: 20 }}>
                <Card bordered={true} styles={{body : {padding: 10}}} loading={isLoading}>
                  
                  <Row gutter={[10,10]} style={{ paddingBottom: 10 }}>  
                      <Col span={18} xs={24} xl={18} style={{display: 'flex' }}>
                        <Space style={{ paddingLeft: 2 }}>
                          <Title level={5}>
                            {t("page.battery") + ": " + batteryData.name + " - " + boilerRoomData.companyName}
                          </Title>
                        </Space>
                      </Col>
                      <Col span={6} xs={24} xl={6} style={{display: 'flex' }}>
                        <Select
                          placeholder={t("page.battery_change")}
                          optionFilterProp="children"
                          style={{width: '100%'}}
                          loading={isBatteriesLoading}
                          onFocus={() => fetchCompanyBatteries(boilerRoomData.companyId)}
                          options={batteryListData}
                          onChange={changeBatteries}
                        >
                        </Select>
                      </Col>
                  </Row>

                  <Row gutter={[10,10]} style={{ paddingBottom: 10 }}>
                    <Col span={6} xs={24} xl={6}>
                      <BatteryChargeTodayCard batteryId={batteryData.id} />
                    </Col>
                    <Col span={6} xs={24} xl={6}>
                      <BatteryChargeTotalCard batteryId={batteryData.id} />
                    </Col>
                    <Col span={6} xs={24} xl={6}>
                      <BatteryDischargeTodayCard batteryId={batteryData.id} />
                    </Col>
                    <Col span={6} xs={24} xl={6}>
                      <BatteryDischargeTotalCard batteryId={batteryData.id} />
                    </Col>
                  </Row>

                  <Row gutter={[10,10]} style={{ paddingBottom: 10 }}>
                    {boilerRoomData.hasThermoSensor ? 
                      <Col span={8} xs={24} xl={8} style={{display: 'flex'}}>
                        <Card size='small'
                          style={{width: '100%'}}
                          bordered={true} 
                          loading={isLoading}
                        >
                          { getWeather(boilerRoomData.id, false) }
                        </Card>
                      </Col>
                    : <></> }

                    <Col span={6} xs={24} xl={6} style={{display: 'flex'}}>
                      <Card size='small'
                        style={{width: '100%'}}
                        bordered={true} 
                        loading={isLoading}
                      >
                        <Row gutter={[10,10]}>
                          <Col span={24} align="center">
                            <BatteryPowerChart batteryId={batteryData.id} />
                          </Col>
                        </Row>
                      </Card>
                    </Col>

                    <Col span={10} xs={24} xl={boilerRoomData.hasThermoSensor ? 10 : 10} style={{display: 'flex'}}>
                      <Card size='small'
                        style={{width: '100%'}}
                        bordered={true} 
                        loading={isLoading}
                      >
                        <Row gutter={[10,10]}>
                          <Col span={8} xs={24} xl={8} align="center">
                            <BatteryChargeStateChart batteryId={batteryData.id} />
                          </Col>
                          <Col span={8} xs={24} xl={8} align="center">
                            <BatteryEnergyStateChart batteryId={batteryData.id} />
                          </Col>
                          <Col span={8} xs={24} xl={8} align="center">
                            <BatteryHeatStateChart batteryId={batteryData.id} />
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>

                  <Row gutter={[10,10]}>

                    {isMobile ?
                      <Col span={24} style={{ marginBottom: 10 }}>
                        {changeUnitAction}
                      </Col>
                    : <></>}
                    
                    <Col span={24}>
                      <Tabs 
                        type="card" 
                        onTabClick={() => changeTab()} 
                        activeKey={selectedKey} 
                        destroyInactiveTabPane={true}
                        tabBarExtraContent={!isMobile ? changeUnitAction : <></>}
                      >
                        <Tabs type="card" tab={t("page.charging")} key="batteryChargingTab">
                          <BatteryChargingChart batteryId={batteryData.id} unit={selectedUnit} refresh={isLoading} dateRange={dateRange} changeDateRange={changeDateRange} groupBy={groupBy} changeGroupBy={changeGroupBy} />
                        </Tabs>
                        <Tabs type="card" tab={t("page.capacity")} key="batteryCapacityTab">
                          <BatteryCapacityChart batteryId={batteryData.id} unit={selectedUnit} refresh={isLoading} dateRange={dateRange} changeDateRange={changeDateRange} groupBy={groupBy} changeGroupBy={changeGroupBy} />
                        </Tabs>
                        <Tabs type="card" tab={t("Ceny energetických vstupov")} key="pricesTab">
                          <ElectricityTab boilerRoomId={batteryData.id} unit={selectedUnit} refresh={isLoading} dateRange={dateRange} changeDateRange={changeDateRange} />
                        </Tabs>
                      </Tabs>
                    </Col>
                  </Row>

                </Card>
            </Col>
        </Row>
      </>
    );
};

export default Battery;