import { useEffect, useState } from 'react';
import { Modal, Form, Alert, Select, Divider, Spin } from 'antd';
import { useAxios } from '../../util/AxiosUtil';
import { useKeycloak } from '@react-keycloak/web';
import { useTranslation } from "react-i18next";
import 'leaflet/dist/leaflet.css';
import moment from 'moment';

const BuildingInsert = ({ branchData = {}, setVisible, visible }) => {

    const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);
    const { keycloak } = useKeycloak();
    const { i18n, t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();
    const [formErrorText, setFormErrorText] = useState();
    const [buildings, setBuildings] = useState([]);

    useEffect(() => {
      fetchBuildings({offset: 1, limit: 100, orderBy: 'createdOn', order: 'descend'});
    }, [branchData.id]);

    const fetchBuildings = async (searchCriteria = {}) => {
      
      setIsLoading(true);
      await axiosClient.get(process.env.REACT_APP_ENEGRAF_DATA_PATH+'/building/company?companyId='+branchData.boilerRoom.companyId+'&search=branchId:0', {
        params: searchCriteria
      }).then(response => {
        setBuildings(response.data.content);
        setIsLoading(false);
      }).catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
    };

    const onSubmit = (formVals) => {
      const building = buildings.filter(item => item.id === formVals.id)[0];

      building.branchId = branchData.id;
      building.modifiedBy = keycloak.idTokenParsed.preferred_username;
      building.modifiedOn = moment();

      setIsLoading(true);
      axiosClient.put(process.env.REACT_APP_ENEGRAF_DATA_PATH+'/building', building)
      .then(response => {
        fetchBuildings({offset: 1, limit: 100, orderBy: 'createdOn', order: 'descend'});
        form.resetFields();
        setIsLoading(false);
        setVisible('refresh');
      }).catch(error => {
        setIsLoading(false);
        console.error('There was an error!', error.response.data);
        setFormErrorText(error.response.data.errors);
      });
    };

    const onClose = () => {
      setVisible(false);
    };

    const layout = {
      labelCol: { span: 6, },
      wrapperCol: { span: 12,},
    };

    return (
      <Modal 
        title={t("page.building_create")+":"}
        visible={visible} 
        confirmLoading={isLoading}
        onOk={() => {
          form
            .validateFields()
            .then(values => {
              onSubmit(values);
            })
            .catch(info => {
              console.log('Validate Failed:', info);
            });
        }}
        onCancel={onClose}
      >
        {formErrorText ? (
          <Alert message={formErrorText} type="error" />
        ) : null}
          <Divider />
          <Spin spinning={isLoading}>
            <Form {...layout} form={form} onFinish={onSubmit}>
              <Form.Item name="id" label={t("page.building")}>
                <Select>
                  <Option value=""></Option>
                  {buildings.map((option) => (
                    <Select.Option key={option.id} value={option.id}>{option.name}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>
          </Spin>
      </Modal>
    );
};

export default BuildingInsert;