import { useState, useEffect } from 'react';
import { Typography, Space, Spin } from 'antd';
import { useAxios } from '../../util/AxiosUtil';
import { useTranslation } from "react-i18next";
import dayjs from 'dayjs';

import { WiDaySunnyOvercast } from "weather-icons-react";

const History = ({ boilerRoomId, day }) => {
    
    const dateFormat = "YYYY-MM-DD";
    const { Title } = Typography;
    
    const { i18n, t } = useTranslation();
    const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);

    const [forecastData, setForecastData] = useState();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
      customFetch();
    }, [day]);

    const customFetch = async () => {
      setIsLoading(true);
      await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/boilerRoom/forecast/'+boilerRoomId+'/'+day.format(dateFormat))
      .then(response => {
          setForecastData(response.data);
          setIsLoading(false);
      }).catch('error: ' + console.log);
    };

    const getNameOfDay = (day) => {

      switch(day.day()) {
        case 1:
          return t("page.monday");
        case 2:
          return t("page.tuesday");
        case 3:
          return t("page.wednesday");
        case 4:
          return t("page.thursday");
        case 5:
          return t("page.friday");
        case 6:
          return t("page.saturday");
        case 0:
          return t("page.sunday");
      }
    };

    return (
      <Spin spinning={isLoading}>
        {forecastData ?
        <>
          {dayjs().format("DD.MM.YYYY") === day.format("DD.MM.YYYY") ?
            <>
              <Space style={{width: '100%', justifyContent: 'center'}}>{t("page.now")}</Space>
              <Title level={3} align="center" style={{margin: 0, paddingTop: 8, paddingBottom: 7}}>{parseFloat(forecastData.maxTemp).toFixed(1)}°</Title>
              <Space style={{width: '100%', justifyContent: 'center'}}>
                <WiDaySunnyOvercast size={50} color='#0070cc' />
              </Space>
            </>
          :
            <>
              <Space style={{width: '100%', justifyContent: 'center'}}>{getNameOfDay(day)}</Space>
              <Title level={5} align="center" style={{margin: 0, paddingTop: 11, paddingBottom: 11}}>{parseFloat(forecastData.minTemp).toFixed(0)}/{parseFloat(forecastData.maxTemp).toFixed(0)}°</Title>
              <Space style={{width: '100%', justifyContent: 'center'}}>
                <WiDaySunnyOvercast size={50} color='#0070cc' />
              </Space>
            </>
          }
        </>
        :<></>}
      </Spin>
    );
};

export default History;