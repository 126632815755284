import { useState } from 'react';
import { Modal, Form, Input, Alert, Divider } from 'antd';
import { useTranslation } from "react-i18next";
import { useAxios } from '../../util/AxiosUtil';
import { useKeycloak } from '@react-keycloak/web';
import moment from 'moment';

const BranchInsert = ({ boilerRoomData = {}, setVisible, visible }) => {

    const { i18n, t } = useTranslation();

    const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);
    const { keycloak } = useKeycloak();

    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();
    const [formErrorText, setFormErrorText] = useState();

    const onSubmit = (formVals) => {

      formVals.id = 0;
      formVals.boilerRoom = boilerRoomData;
      formVals.createdBy = keycloak.idTokenParsed.preferred_username;
      formVals.createdOn = moment();
      formVals.modifiedBy = keycloak.idTokenParsed.preferred_username;
      formVals.modifiedOn = moment();
      
      setIsLoading(true);
      axiosClient.post(process.env.REACT_APP_ENERPO_DATA_PATH+'/branch', formVals)
      .then(response => {
        form.resetFields();
        
        setIsLoading(false);
        setVisible('refresh');
      }).catch(error => {
        setIsLoading(false);
        console.error('There was an error!', error.response.data);
        setFormErrorText(error.response.data.errors);
      });
    };

    const onClose = () => {
      setVisible(false);
    };

    const layout = {
      labelCol: { span: 6, },
      wrapperCol: { span: 12,},
    };

    return (
      <Modal 
        title={t("page.branch_create")+":"}
        visible={visible} 
        confirmLoading={isLoading}
        onOk={() => {
          form
            .validateFields()
            .then(values => {
              onSubmit(values);
            })
            .catch(info => {
              console.log('Validate Failed:', info);
            });
        }}
        onCancel={onClose}
      >
        {formErrorText ? (
          <Alert message={formErrorText} type="error" />
        ) : null}
          <Divider />
          <Form {...layout} form={form} onFinish={onSubmit}>
            <Form.Item name="name" label={t("page.name")} rules={[{required: true, message: t("page.name.mandatory")}]}>
              <Input />
            </Form.Item>
          </Form>
      </Modal>
    );
};

export default BranchInsert;