import { useState, useEffect, useCallback } from 'react';
import { Card, Row, Col, Space, Typography, Select, Tabs, Tooltip } from 'antd';
import { useAxios } from '../../util/AxiosUtil.js';
import { useTranslation } from "react-i18next";

import isInRole from '../../security/IsInRole';
import Unauthorized from '../error/Unauthorized';
import dayjs from 'dayjs';

import BoilerRoomTable from '../boiler_room/BoilerRoomTable.js';

const BoilerRoomList = () => {

  const { i18n, t } = useTranslation();
  const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);
  const { Title } = Typography;

  const [isMobile, setIsMobile] = useState(window.innerWidth < 700);
  const [visibleForm, setVisibleForm] = useState(false);
  const [refreshForm, setRefreshForm] = useState(false);

  const [selectedKey, setSelectedKey] = useState();
  const [selectedUnit, setSelectedUnit] = useState('kWh');
  const [selectedBoilerRoom, setSelectedBoilerRoom] = useState([]);
  const [boilerRoomListData, setBoilerRoomListData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isBoilerRoomLoading, setIsBoilerRoomLoading] = useState(false);
  const [dateRange, setDateRange] = useState([dayjs(), dayjs()]);
  const [groupBy, setGroupBy] = useState("hour");

  const changeUnitAction =
  <Select 
    onChange={setSelectedUnit} 
    defaultValue={{ value: 'column', label: 'kWh' }}
    style={{ width: isMobile ? '100%' : 80 }} 
    options={[ { value: 'kWh', label: 'kWh' }, { value: 'MWh', label: 'MWh' } ]}
  />;

  const changeBoilerRoomAction =
  <Select 
    mode="multiple"
    maxTagCount='responsive'
    allowClear
    maxTagPlaceholder={(omittedValues) => (
      <Tooltip title={omittedValues.map(({ label }) => label).join(', ')}>
        <span>{t("page.selected")+"..."}</span>
      </Tooltip>
    )}
    onChange={setSelectedBoilerRoom}
    suffixIcon={<span>{selectedBoilerRoom.length} / {boilerRoomListData.length}</span>}
    placeholder={t("page.boiler_room_change")}
    style={{ width: isMobile ? '100%' : 180 }} 
    loading={isBoilerRoomLoading}
    value={selectedBoilerRoom}
    options={boilerRoomListData}
  />;

  useEffect(() => {
    fetchCompanyBoilerRooms();
  }, []);

  const fetchCompanyBoilerRooms = async () => {

    setIsBoilerRoomLoading(true);
    setBoilerRoomListData([]);
    await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/boilerRoom?limit=50')
    .then(response => {

      var myJson = [];
      for (var k = 0; k < response.data.content.length; k++) {
        myJson.push({
          value: response.data.content[k].id,
          label: response.data.content[k].name
        });
      }

      setSelectedBoilerRoom(myJson.map(a => a.value));
      setBoilerRoomListData(myJson);
      setIsBoilerRoomLoading(false);
    }).catch('error: ' + console.log);
  };

  const changeTab = (tab) => {
    setSelectedKey(tab);
  };

  const changeDateRange = useCallback((value) => {
    setDateRange(value);
  }, []);

  const changeGroupBy = useCallback((value) => {
    setGroupBy(value);
    value === 'hour' ? setDateRange([dayjs(), dayjs()]) 
    : value === 'day' ? setDateRange([dayjs().subtract(14, 'd'), dayjs()]) 
    : setDateRange([dayjs().subtract(4, 'M'), dayjs()]);
  }, []);

  return (
    <>
      {isInRole(['enerpo_web']) ?
        <Row gutter={16} style={{ marginTop: 10, marginBottom: 20 }}>
          <Col span={24}>
              <Card size="small">

                <Row gutter={[10,10]}>
                  <Col span={18} xs={24} xl={18} style={{display: 'flex' }}>
                    <Space style={{ paddingLeft: 2 }}>
                      <Title level={5}>{t("page.menu.boiler_rooms")}</Title>
                    </Space>
                  </Col>

                  {isMobile ?
                    <>
                      <Col span={24} style={{ marginBottom: 5 }}>
                        {changeBoilerRoomAction}
                      </Col>
                      <Col span={24} style={{ marginBottom: 10 }}>
                        {changeUnitAction}
                      </Col>
                    </>
                  : <></>}
{/*
                  <Col span={24}>
                    <Row gutter={[10,10]}>
                      <Col span={8} xs={24} xl={8}>
                        <EntityCountPieChart boilerRoomIds={selectedBoilerRoom} />
                      </Col>
                      <Col span={8} xs={24} xl={8}>
                        <Row gutter={[10,10]}>
                          <Col span={24}>
                            <CopColumnChart />
                          </Col>
                          <Col span={24}>
                            <CopColumnChart />
                          </Col>
                        </Row>
                      </Col>
                      <Col span={8} xs={24} xl={8}>
                        <Row gutter={[10,10]}>
                          <Col span={24}>
                            <Card />
                          </Col>
                          <Col span={24}>
                            <Card />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>


                  <Col span={24}>
                    <Tabs
                      type="card" 
                      onTabClick={() => changeTab()} 
                      activeKey={selectedKey} 
                      destroyInactiveTabPane={true}
                      tabBarExtraContent={!isMobile ? <>{changeBoilerRoomAction} {changeUnitAction}</> : <></>}
                    >
                      <Tabs type="card" tab={t("page.heat_pumps")} key="heatPumpTab">
                        <Row gutter={[10,10]}>
                          <Col span={8} xs={24} xl={8}>
                            <HeatPumpAllStatusPieChart boilerRoomIds={selectedBoilerRoom} unit={selectedUnit}/>
                          </Col>
                          <Col span={16} xs={24} xl={16}>
                            <HeatPumpAllCopLineChart boilerRoomIds={selectedBoilerRoom} unit={selectedUnit} dateRange={dateRange} changeDateRange={changeDateRange} groupBy={groupBy} changeGroupBy={changeGroupBy} />
                          </Col>
                        </Row>
                      </Tabs>
                      <Tabs type="card" tab={t("page.fves")} key="fveTab">
                        <Row gutter={[10,10]}>
                          <Col span={8} xs={24} xl={8}>
                            
                          </Col>
                          <Col span={16} xs={24} xl={16}>
                            
                          </Col>
                        </Row>
                      </Tabs>
                      <Tabs type="card" tab={t("page.batteries")} key="batteryTab">
                        <Row gutter={[10,10]}>
                          <Col span={8} xs={24} xl={8}>
                            
                          </Col>
                          <Col span={16} xs={24} xl={16}>
                            
                          </Col>
                        </Row>
                      </Tabs>
                    </Tabs>
                  </Col>
*/}
                </Row>

                <Row gutter={16} style={{ marginTop: 10 }}>
                  <Col span={24}>
                      <Card size="small">
                        <BoilerRoomTable setRefresh={setRefreshForm} refresh={refreshForm} />
                      </Card>
                  </Col>
                </Row>

                {/*
                  <BoilerRoomInsert setVisible={showInsertForm} visible={visibleForm} />
                */}
              </Card>
          </Col>
        </Row>
      : <Unauthorized />}
    </>
  );
};

export default BoilerRoomList;