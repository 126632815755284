import { useState, useEffect, useContext } from 'react';

import { Tabs, Card } from 'antd';
import { useAxios } from '../../../util/AxiosUtil';
import { useTranslation } from "react-i18next";
import { LocaleContext } from '../../../context/LocaleContext';

import ElEnergyOm from './entry/sub_tabs/tn_kratka/ElEnergyOm';
import ElEnergyTc from './entry/sub_tabs/tn_kratka/ElEnergyTc';
import ElGassWithPowerTab from './entry/sub_tabs/tn_kratka/ElGassWithPowerTab';

import QuantityEeFromFweTab from './tuv/sub_tabs/tn_kratka/QuantityEeFromFweTab';
import ProducedHeatAllTab from './tuv/sub_tabs/tn_kratka/ProducedHeatAllTab';
import DeliveredHeatAllTab from './tuv/sub_tabs/tn_kratka/DeliveredHeatAllTab';

import TuvAllTab from './tuv/sub_tabs/tn_kratka/TuvAllTab';
import HeatUkTab from './tuv/sub_tabs/bosany/HeatUkTab';
import HeatTuvTab from './tuv/sub_tabs/bosany/HeatTuvTab';

import CopTab from './kpi/sub_tabs/tn_kratka/CopTab';
import DistributionEfficiencyUkTab from './kpi/sub_tabs/tn_kratka/DistributionEfficiencyUkTab';
import DistributionProfitLossTab from './kpi/sub_tabs/tn_kratka/DistributionProfitLossTab';
import LostOnSourceTab from './kpi/sub_tabs/tn_vychodna/LostOnSourceTab';
import LostOnPrimarTab from './kpi/sub_tabs/tn_vychodna/LostOnPrimarTab';

import ElectricityTab from './prices/sub_tabs/ElectricityTab';
import GassTab from './prices/sub_tabs/GassTab';

import SingleSourceTab from './default/SingleSourceTab';

const BoilerRoomTabPane = ({ boilerRoomTabId, boilerRoomId, unit, refresh, dateRange, changeDateRange, groupBy, changeGroupBy }) => {

  const { i18n, t } = useTranslation();
  const { locale, setLocale } = useContext(LocaleContext);

  const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);

  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState(true);
  const [activeKey, setActiveKey] = useState(1);

  useEffect(() => {
    customFetch();
  }, [boilerRoomTabId, unit, dateRange, groupBy, locale]);

  const customFetch = async () => {
    setIsLoading(true);
    await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/boilerRoom/tabItem/'+boilerRoomTabId)
    .then(response => {
        var myJson = [];
        for (var k = 0; k < response.data.length; k++) {
          myJson.push({
            key: response.data[k].tabKey,
            label: t(response.data[k].label),
            children: getChartByName(response.data[k].chartName, response.data[k].unit, response.data[k].label)
          });
        }

        setItems(myJson);
        setIsLoading(false);
    }).catch('error: ' + console.log);
  };

  const changeActiveKey = (key) => {
    setActiveKey(key);
  }

  function getChartByName(chartName, chartUnit, chartLabel) {
    switch (chartName) {
// TN Kratka: --->
      case 'ElEnergyOm':
        return <ElEnergyOm boilerRoomId={boilerRoomId} unit={unit} refresh={refresh} dateRange={dateRange} changeDateRange={changeDateRange} groupBy={groupBy} changeGroupBy={changeGroupBy} />
      case 'ElEnergyTc':
        return <ElEnergyTc boilerRoomId={boilerRoomId} unit={unit} refresh={refresh} dateRange={dateRange} changeDateRange={changeDateRange} groupBy={groupBy} changeGroupBy={changeGroupBy} />
      case 'ElGassWithPowerTab':
        return <ElGassWithPowerTab boilerRoomId={boilerRoomId} unit={unit} refresh={refresh} dateRange={dateRange} changeDateRange={changeDateRange} groupBy={groupBy} changeGroupBy={changeGroupBy} />
      case 'QuantityEeFromFweTab':
        return <QuantityEeFromFweTab boilerRoomId={boilerRoomId} unit={unit} refresh={refresh} dateRange={dateRange} changeDateRange={changeDateRange} groupBy={groupBy} changeGroupBy={changeGroupBy} />
      case 'ProducedHeatAllTab':
        return <ProducedHeatAllTab boilerRoomId={boilerRoomId} unit={unit} refresh={refresh} dateRange={dateRange} changeDateRange={changeDateRange} groupBy={groupBy} changeGroupBy={changeGroupBy} />
      case 'DeliveredHeatAllTab':
        return <DeliveredHeatAllTab boilerRoomId={boilerRoomId} unit={unit} refresh={refresh} dateRange={dateRange} changeDateRange={changeDateRange} groupBy={groupBy} changeGroupBy={changeGroupBy} />
      case 'TuvAllTab':
        return <TuvAllTab boilerRoomId={boilerRoomId} unit={unit} refresh={refresh} dateRange={dateRange} changeDateRange={changeDateRange} groupBy={groupBy} changeGroupBy={changeGroupBy} />
      case 'CopTab':
        return <CopTab boilerRoomId={boilerRoomId} unit={unit} refresh={refresh} dateRange={dateRange} changeDateRange={changeDateRange} groupBy={groupBy} changeGroupBy={changeGroupBy} />
      case 'DistributionEfficiencyUkTab':
        return <DistributionEfficiencyUkTab boilerRoomId={boilerRoomId} unit={unit} refresh={refresh} dateRange={dateRange} changeDateRange={changeDateRange} groupBy={groupBy} changeGroupBy={changeGroupBy} />
      case 'DistributionProfitLossTab':
        return <DistributionProfitLossTab boilerRoomId={boilerRoomId} unit={unit} refresh={refresh} dateRange={dateRange} changeDateRange={changeDateRange} groupBy={groupBy} changeGroupBy={changeGroupBy} />
      case 'ElectricityTab':
        return <ElectricityTab />
      case 'GassTab':
        return <GassTab />
// TN Bosany: --->
      case 'HeatUkTab':
        return <HeatUkTab boilerRoomId={boilerRoomId} unit={unit} refresh={refresh} dateRange={dateRange} changeDateRange={changeDateRange} groupBy={groupBy} changeGroupBy={changeGroupBy} />
      case 'HeatTuvTab':
        return <HeatTuvTab boilerRoomId={boilerRoomId} unit={unit} refresh={refresh} dateRange={dateRange} changeDateRange={changeDateRange} groupBy={groupBy} changeGroupBy={changeGroupBy} />
// TN Vychodna: --->
      case 'LostOnSourceTab':
        return <LostOnSourceTab boilerRoomId={boilerRoomId} unit={unit} refresh={refresh} dateRange={dateRange} changeDateRange={changeDateRange} groupBy={groupBy} changeGroupBy={changeGroupBy} />
      case 'LostOnPrimarTab':
        return <LostOnPrimarTab boilerRoomId={boilerRoomId} unit={unit} refresh={refresh} dateRange={dateRange} changeDateRange={changeDateRange} groupBy={groupBy} changeGroupBy={changeGroupBy} />
// TN 28 Oktobra: --->

      default :
        return <SingleSourceTab boilerRoomId={boilerRoomId} chart={chartName} chartLabel={chartLabel} chartUnit={chartUnit} unit={unit} refresh={refresh} dateRange={dateRange} changeDateRange={changeDateRange} groupBy={groupBy} changeGroupBy={changeGroupBy} />
    }
  }

  return (
    <>
      <Card size='small' bodyStyle={{ paddingTop: 0 }} loading={isLoading}>
        <Tabs
          defaultActiveKey={activeKey}
          onChange={(v) => changeActiveKey(v)}
          items={items}
        />
      </Card>
    </>
  );
};

export default BoilerRoomTabPane;