import { Card } from 'antd';
import { Image } from 'antd';

const ImageTab = ({ image }) => {

    return (
      <Card size="small" style={{ marginTop: 10, paddingBottom: 15 }}>
        <Image src={image} style={{ paddingRight: '10px', width: '100%', paddingTop: '0px'}} />
      </Card>
    );
};

export default ImageTab;