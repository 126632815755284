import { useState, useEffect } from 'react';
import { Empty, Tooltip, Card, Button, Space, Spin } from 'antd';
import { useAxios } from '../../../util/AxiosUtil';
import { useTranslation } from "react-i18next";

import dayjs from 'dayjs';
import { Pie } from '@ant-design/plots';

import { SyncOutlined } from '@ant-design/icons';

const FweAllRevenuePieChart = ({ fweIds }) => {

  const { i18n, t } = useTranslation();
  const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);
  
  const [isMobile, setIsMobile] = useState(window.innerWidth < 700);
  const [graphData, setGraphData] = useState([]);
  const [emptyGraphData, setEmptyGraphData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    customFetch();
  }, [fweIds]);

  useEffect(() => {
    function handleResize() {
        setIsMobile(window.innerWidth < 700)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, []);

  const customFetch = async () => {

    const ids = fweIds.length > 0 ? fweIds : 0;
    setIsLoading(true);
    await axiosClient.get(process.env.REACT_APP_ENERPO_DATA_PATH+'/fwe/'+ids+'/totalRevenues')
    .then(response => {
      setEmptyGraphData(false);
      if (response.data.length === 0)
        setEmptyGraphData(true);

      var myJson = [];
      for (var k = 0; k < response.data.length; k++) {
        if (response.data[k].value !== null) {
          myJson.push({
            value: response.data[k].value,
            type: response.data[k].name,
            date: dayjs(response.data[k].date)
          });
        }
      }

      setGraphData(myJson);
      setIsLoading(false);
    }).catch('error: ' + console.log);
  };

  const refreshChart = () => {
    customFetch();
  }

  const config = {
    data: graphData,
    angleField: 'value',
    colorField: 'type',
    height: 300,
    legend: false,
    innerRadius: 0.6,
    label: false,
    tooltip: {
      formatter: (x) => {
        return { 
          name: x.type,
          value: parseFloat(x.value).toFixed(2) + ' Eur'
        };
      },
    },
    statistic: {
      title: false,
      content: {
        style: {
          fontSize: 18,
        },
        content: parseFloat(`${graphData.reduce((n, {value}) => n + value, 0)}`).toFixed(2) + ' Eur',
      },
    }, 
  };

  return (
    <Spin spinning={isLoading}>
      <Card
        size="small" loading={isLoading} title={t("page.year_revenue") + " " + dayjs().format("YYYY") +":"}
        extra={
            <div style={{ paddingTop: 16, paddingBottom: 16 }}>
              <Space style={{ paddingRight: 5 }}>
                <Tooltip style={{ margin: 15 }} >
                  <Button type="dashes" shape="circle" icon={<SyncOutlined />} onClick={refreshChart} />
                </Tooltip>
              </Space>
            </div>
        }>
          {emptyGraphData ?
            <Empty style={{ height: 300 }} />
          :
            <Pie {...config} loading={isLoading} />
          }
      </Card>
    </Spin>
  );
};

export default FweAllRevenuePieChart;