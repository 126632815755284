import { useEffect, useState } from 'react';
import { Modal, Form, Input, Alert, Select, Divider, Spin } from 'antd';
import { useAxios } from '../../util/AxiosUtil';
import { useKeycloak } from '@react-keycloak/web';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet'
import MapMarker from '../../util/MapMarker';
import 'leaflet/dist/leaflet.css';
import { useTranslation } from "react-i18next";

const BoilerRoomInsert = ({ companyData = {}, setVisible, visible }) => {

    const { i18n, t } = useTranslation();

    const axiosClient = useAxios(process.env.REACT_APP_MCS_API_GATEWAY_URL);
    const { keycloak } = useKeycloak();

    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();
    const [formErrorText, setFormErrorText] = useState();
    const [selectedCompany, setSelectedCompany] = useState([]);
    const [companyListData, setCompanyListData] = useState([]);
    const [projects, setProjects] = useState([]);
    const [projectSchemas, setProjectSchemas] = useState([]);
    const [selectedProject, setSelectedProject] = useState([]);
    const [selectedSchema, setSelectedSchema] = useState([]);

    const [centralMap, setCentralMap] = useState([48.8894822,18.0345196]);
    const [position, setPosition] = useState(centralMap);
    const [address, setAddress] = useState();

    useEffect(() => {
      setSelectedCompany({id : companyData.id, name : companyData.name});
      //fetchProjects();
    }, [companyData.id]);

    useEffect(() => {
      if (!companyData.id)
        fetchCompanyList({offset: 1, limit: 10, orderBy: 'createdOn', order: 'descend'});

    }, [companyData.id]);

    const fetchCompanyList = async (searchCriteria = {}) => {

      setIsLoading(true);
      await axiosClient.get(process.env.REACT_APP_ENEGRAF_DATA_PATH+'/company', {
        params: searchCriteria
      }).then(response => {
        setCompanyListData(response.data.content);
        setIsLoading(false);
      }).catch('error: ' + console.log);
    };

    const changeCompany = (val, company) => {
      setSelectedCompany({id : company.key, name : company.value});
    };

    /*
    const fetchProjects = async () => {
      setIsLoading(true);
      await axiosClient.get(process.env.REACT_APP_DISPATCH_DATA_PATH+'/project')
      .then(response => {
        setProjects(response.data);
        setIsLoading(false);
      }).catch('error: ' + console.log);
    };

    const fetchProjectSchemas = async (val, project) => {

      setProjectSchemas([]);
      form.setFieldsValue({ schema: [] });
      setSelectedProject(project);
      
      setIsLoading(true);
      await axiosClient.get(process.env.REACT_APP_DISPATCH_DATA_PATH+'/schema?projectId='+project.key)
      .then(response => {
        setProjectSchemas(response.data);
        setIsLoading(false);
      }).catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
    };

    const changeProjectSchema = async (val, schema) => {
      setSelectedSchema(schema);
    };
    */

    const onSubmit = (formVals) => {

      formVals.companyId = selectedCompany.id;
      formVals.companyName = selectedCompany.name;
      formVals.lat = position[0];	
      formVals.lng = position[1];
      formVals.createdBy = keycloak.idTokenParsed.preferred_username;
      formVals.createdOn = moment();
      formVals.modifiedBy = keycloak.idTokenParsed.preferred_username;
      formVals.modifiedOn = moment();
      
      setIsLoading(true);
      axiosClient.post(process.env.REACT_APP_ENERPO_DATA_PATH+'/boilerRoom', formVals)
      .then(response => {
        form.resetFields();
        
        setIsLoading(false);
        setVisible('refresh');
      }).catch(error => {
        setIsLoading(false);
        console.error('There was an error!', error.response.data);
        setFormErrorText(error.response.data.errors);
      });
    };

    const onClose = () => {
      setVisible(false);
    };

    const LocationFinder = () => {
      const map = useMapEvents({
          click(e) {
              const { lat, lng } = e.latlng;
              setPosition([lat, lng]);
              form.setFieldsValue({ location: position });

              axiosClient.get('https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat='+lat+'&lon='+lng)
              .then(response => {
                setAddress((response.data.address.road ? response.data.address.road : '') +
                           (response.data.address.house_number ? ' ' + response.data.address.house_number : '') +
                           (response.data.address.town ? ', ' + response.data.address.town : '') +
                           (response.data.address.city ? ', ' + response.data.address.city : ''));
              });

          },
      });
      return null;
    };

    const layout = {
      labelCol: { span: 6, },
      wrapperCol: { span: 12,},
    };

    return (
      <Modal 
        title={t("page.boiler_room_create")+":"} 
        visible={visible} 
        confirmLoading={isLoading}
        onOk={() => {
          form
            .validateFields()
            .then(values => {
              onSubmit(values);
            })
            .catch(info => {
              console.log('Validate Failed:', info);
            });
        }}
        onCancel={onClose}
      >
        {formErrorText ? (
          <Alert message={formErrorText} type="error" />
        ) : null}

          <Spin spinning={isLoading}>
            <Divider />
            <Form {...layout} form={form} onFinish={onSubmit}>

              {!companyData.id ?
                <Form.Item name="companyId" label={t("page.company")} rules={[{required: true, message: t("page.select.company")}]}>
                  <Select onChange={changeCompany}>
                    <Option value=""></Option>
                    {companyListData.map((option) => (
                      <Select.Option key={option.id} value={option.name}>{option.name}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              :
                <Form.Item label={t("page.company")}>
                  <Input value={companyData.name} disabled readOnly/>
                </Form.Item>
              }

              {selectedCompany.id ?
                <>
                  <Divider/>
                  <Form.Item name="name" label={t("page.boiler_room")} rules={[{required: true, message: t("page.select.boiler_room")}]}>
                    <Input />
                  </Form.Item>
                  <Form.Item name="location" label={t("page.location")} rules={[{required: true, message: t("page.select.location")}]}>
                    <MapContainer center={centralMap} zoom={10} scrollWheelZoom={false} style={{ height: 200 }}>
                      <LocationFinder />
                      <TileLayer attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" 
                      />
                      <Marker position={position} icon={ MapMarker }></Marker>
                    </MapContainer>
                    <br/><Input value={address} disabled/>
                  </Form.Item>
                </>
              : <></> }

            </Form>
          </Spin>
      </Modal>
    );
};

export default BoilerRoomInsert;